import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { compose, pipe, assoc, map, find, propEq, prop, join } from 'ramda';
// API
import api from 'lib/api';
// HELPERS
import { customSort } from 'lib/helpers/sorting';

const initialState = {
  startups: [],
  trackingAllStartups: [],
  error: false,
  loading: false,
};

const actions = createActions(
  'FETCH_STARTUPS_REQUEST',
  'FETCH_STARTUPS_SUCCESS',
  'FETCH_STARTUPS_FAILURE',

  'FETCH_TRACKING_ALL_STARTUPS_REQUEST',
  'FETCH_TRACKING_ALL_STARTUPS_SUCCESS',
  'FETCH_TRACKING_ALL_STARTUPS_FAILURE',
);

const reducer = handleActions(
  {
    [actions.fetchTrackingAllStartupsRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.fetchTrackingAllStartupsSuccess]: (state, { payload }) => ({
      ...state,
      trackingAllStartups: payload,
      loading: false,
    }),
    [actions.fetchTrackingAllStartupsFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),

    [actions.fetchStartupsRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.fetchStartupsSuccess]: (state, { payload: startups }) => ({
      ...state,
      startups,
      loading: false,
    }),
    [actions.fetchStartupsFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  fetchStartups: () => async dispatch => {
    try {
      await dispatch(actions.fetchStartupsRequest());
      const { data, meta } = await api.getStartups();
      if (meta.success) {
        await dispatch(actions.fetchStartupsSuccess(data));
      }
    } catch (error) {
      dispatch(actions.fetchStartupsFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
    return true;
  },
};

const getState = state => state.startups;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getStartups: cs(
    s =>
      s.startups &&
      s.startups.length >= 0 &&
      compose(
        pipe(e =>
          customSort(e, {
            key: 'id',
            keyType: 'number',
            direction: 'desc',
          }),
        ),
        map(
          pipe(e =>
            assoc(
              'ownerName',
              join(' ', [prop('name', e.user), prop('last_name', e.user)]),
              e,
            ),
          ),
        ),
      )(s.startups),
  ),
  getStartupById: cs(
    (s, id) => s.startups && find(propEq('id', id), s.startups),
  ),
  getTrackingAllStartups: cs(s => s.trackingAllStartups),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
};

export { initialState as state, reducer, actions, selectors, effects };
