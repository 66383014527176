// @flow
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import styled from 'styled-components';
// STYLES
import { media } from 'lib/styles';
// HOCS
import { withSidebar } from 'components/HOC';
// ASSETS
import mainBg from 'assets/images/main-bg-2.jpg';
// HELPERS
import { getToken } from 'lib/helpers/token';
// DUCKS
import {
  effects as authEffects,
  selectors as authSelectors,
} from 'redux/ducks/common/auth.duck';
import {
  effects as userEffects,
  selectors as userSelectors,
} from 'redux/ducks/common/admin.duck';
// COMPONENTS
import { LoadingScreen } from 'components/Layout/Screens';
import Modal from 'components/Layout/Modals';
import Toasts from 'components/Layout/Toasts';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
// TYPES
type Props = Object;
// EXPORTED HOC
const MainLayout = ({
  children,
  sidebarProps,
  handleOpenSidebar,
  handleCloseSidebar,
  isEditor,
  ...rest
}: Props) => {
  const { token, authRefreshToken, getInitialData, user } = rest;

  useEffect(() => {
    authRefreshToken();
    getInitialData();
  }, [authRefreshToken, getInitialData, token]);

  if (!token) return <Redirect to="/signin" />;

  if (!user) return <LoadingScreen />;

  if (isEditor) return <Redirect to="/courses" />;

  return (
    <Content {...rest}>
      <Header
        {...rest}
        {...sidebarProps}
        handleOpenSidebar={handleOpenSidebar}
      />
      <Grid>
        <Sidebar
          {...rest}
          {...sidebarProps}
          handleCloseSidebar={handleCloseSidebar}
        />
        <InnerContent {...rest}>
          {React.cloneElement(children, { ...rest })}
        </InnerContent>
      </Grid>
      <Footer />
      <Modal />
      <Toasts />
    </Content>
  );
};

export default compose(
  connect(
    state => ({
      token: authSelectors.getToken(state) || getToken(),
      user: userSelectors.getInfoProfile(state),
    }),
    { ...authEffects, ...userEffects },
  ),
  withSidebar,
)(MainLayout);

const Content = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  background-image: url(${mainBg});
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  header {
    margin-bottom: 58px;
  }

  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  ${media.tablet`
    padding-top: 0;
  `};
`;

const Grid = styled.div`
  position: relative;
  flex: 1;
  display: grid;
`;

const InnerContent = styled.div`
  display: grid;
  justify-content: center;
  ${media.tablet`
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    max-height: 100%;
    overflow-y: initial;
  `};
`;
