// @ flow
import * as React from 'react';
import styled from 'styled-components';
// COMPONENTS
import { Button } from 'components/UI/Buttons';
// ASSETS
import { MdError } from 'react-icons/md';
import { media } from 'lib/styles';
// TYPES
type Props = {
  closeModal: Function,
  removalHandler: Function,
  title?: string,
};
// EXPORTED COMPONENT
const RemovalConfirmation = ({ closeModal, removalHandler, title }: Props) => (
  <Content>
    <MdError size="80px" color="#e40043" />
    <Title>{title}</Title>
    <ButtonsWrap>
      <Button
        name="Да"
        height="60px"
        width="200px"
        buttonColor="pink"
        onClick={() => removalHandler()}
      />
      <Button
        name="Нет"
        height="60px"
        width="200px"
        buttonColor="blue"
        onClick={() => closeModal()}
      />
    </ButtonsWrap>
  </Content>
);

RemovalConfirmation.defaultProps = {
  title: 'Хотите удалить?',
};

export default RemovalConfirmation;

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  align-content: center;
  justify-items: center;
  ${media.phone`
    text-align: center;
  `}
  ${media.smallPhone`
    padding: 15px;
  `}
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
`;

const ButtonsWrap = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 50% auto;
  ${media.smallPhone`
    width: 100%;
    grid-template-columns: auto;
    > button {
      width: 100%;
    }
  `}
`;
