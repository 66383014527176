export default options => ({
  id: null,
  title: '',
  text: '',
  comment: '',
  picture: null,
  owner: '',
  owner_position: '',
  created_at: '',
  ...options,
});
