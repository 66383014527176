// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'lib/history';
import { pipe, assoc, map, prop, join } from 'ramda';
// API
import api from 'lib/api';
// FACTORIES
import createCourse from 'lib/factories/createCourse';
// DUCKS
import { effects as coursesEffects } from './courses.duck';
import { actions as toastsActions } from './common/toasts.duck';
// INITIAL STATE
const initialState = {
  course: createCourse(),
  loading: false,
  errorMessage: null,
};
// ACTIONS
const actions = createActions(
  'FETCH_COURSE_REQUEST',
  'FETCH_COURSE_SUCCESS',
  'FETCH_COURSE_FAILURE',

  'CREATE_COURSE_REQUEST',
  'CREATE_COURSE_SUCCESS',
  'CREATE_COURSE_FAILURE',

  'UPDATE_COURSE_REQUEST',
  'UPDATE_COURSE_SUCCESS',
  'UPDATE_COURSE_FAILURE',

  'REMOVE_COURSE_REQUEST',
  'REMOVE_COURSE_SUCCESS',
  'REMOVE_COURSE_FAILURE',

  'CLEAR_COURSE',
);

const effects = {
  // $FlowFixMe
  fetchCourse: id => async dispatch => {
    try {
      await dispatch(actions.fetchCourseRequest());
      const { data, meta } = await api.getCourse(id);
      if (meta.success) {
        await dispatch(actions.fetchCourseSuccess(data));
      }
      return data;
    } catch (error) {
      dispatch(actions.fetchCourseFailure(error.message));
      dispatch(toastsActions.addToast({ text: 'Курс не найден', error: true }));
      history.push(`/courses`);
      return new Promise(resolve => resolve(error.message));
    }
  },
  // $FlowFixMe
  createCourse: payload => async dispatch => {
    try {
      const { data, meta } = await api.createCourse(payload);
      if (meta.success) {
        await dispatch(actions.createCourseSuccess(data));
        history.push(`/courses/${data.id}/sections`);
        await dispatch(coursesEffects.fetchCourses());
      }
      return true;
    } catch (error) {
      await dispatch(actions.createCourseFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  updateCourse: payload => async dispatch => {
    try {
      await api.updateCourse(payload);
      await dispatch(actions.updateCourseSuccess());
      history.push(`/courses/${payload.id}/sections`);
      return true;
    } catch (error) {
      await dispatch(actions.updateCourseFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  removeCourse: id => async dispatch => {
    try {
      const { meta } = await api.removeCourse(id);
      if (meta.success) {
        await dispatch(actions.removeCourseSuccess());
        await dispatch(coursesEffects.fetchCourses());
        history.push(`/courses`);
      }
      return meta.success;
    } catch (error) {
      await dispatch(actions.removeCourseFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchCourseRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchCourseSuccess]: (state, { payload: course }) => ({
      ...state,
      course,
      loading: false,
    }),
    [actions.fetchCourseFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.createCourseRequest]: (state, { payload: course }) => ({
      ...state,
      course,
      loading: true,
      error: false,
    }),
    [actions.createCourseSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createCourseFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.updateCourseRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.updateCourseSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateCourseFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.removeCourseSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.removeCourseFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.clearCourse]: () => initialState,
  },
  initialState,
);

const getState = state => state.course;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

// $FlowFixMe
const selectors = {
  getCourse: cs(s => s.course),
  getLoading: cs(s => s.loading),
  getCourseApplications: cs(
    s =>
      s.course &&
      s.course.users &&
      map(
        pipe(e =>
          assoc(
            'userName',
            join(' ', [prop('name', e), prop('last_name', e)]),
            e,
          ),
        ),
      )(s.course.users),
  ),
};

export { initialState as state, actions, effects, reducer, selectors };
