// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// import { pipe, assoc, map, prop, join } from 'ramda';
// API
import api from 'lib/api';
// DUCKS
import { effects as lessonEffects } from './lesson.duck';
import { actions as toastsActions } from './common/toasts.duck';
// INITIAL STATE
const initialState = {
  loading: false,
  errorMessage: '',
};
// ACTIONS
const actions = createActions(
  'DOWNLOAD_ATTACHMENT_REQUEST',
  'DOWNLOAD_ATTACHMENT_SUCCESS',
  'DOWNLOAD_ATTACHMENT_FAILURE',

  'DELETE_ATTACHMENT_REQUEST',
  'DELETE_ATTACHMENT_SUCCESS',
  'DELETE_ATTACHMENT_FAILURE',

  'EDIT_ATTACHMENT_REQUEST',
  'EDIT_ATTACHMENT_SUCCESS',
  'EDIT_ATTACHMENT_FAILURE',

  'UPLOAD_FILES_LESSON_REQUEST',
  'UPLOAD_FILES_LESSON_SUCCESS',
  'UPLOAD_FILES_LESSON_FAILURE',
);

const effects = {
  // $FlowFixMe
  downloadAttachment: id => async dispatch => {
    try {
      dispatch(actions.downloadAttachmentRequest());
      const file = await api.downloadAttachment(id);
      dispatch(actions.downloadAttachmentSuccess(file));
      return file;
    } catch (error) {
      dispatch(actions.downloadAttachmentFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  // $FlowFixMe
  deleteAttachment: ({ uuid, lesson_id }) => async dispatch => {
    try {
      dispatch(actions.deleteAttachmentRequest());
      const { meta } = await api.deleteAttachment(uuid);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Файл удалён' }));
        dispatch(actions.deleteAttachmentSuccess());
        if (lesson_id)
          await dispatch(lessonEffects.fetchCourseSectionLesson(lesson_id));
        return true;
      }
      throw new Error('Something goes wrong: deleteAttachment');
    } catch (error) {
      dispatch(toastsActions.addToast({ text: 'Файл не удалён', error: true }));
      dispatch(actions.deleteAttachmentFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  editAttachment: ({ lesson_id, ...payload }) => async dispatch => {
    try {
      dispatch(actions.editAttachmentRequest());
      const { meta } = await api.editAttachment(payload);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Файл изменён' }));
        dispatch(actions.editAttachmentSuccess());
        if (lesson_id)
          await dispatch(lessonEffects.fetchCourseSectionLesson(lesson_id));
        return true;
      }
      throw new Error('Something goes wrong: editAttachment');
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Файл не изменён', error: true }),
      );
      dispatch(actions.editAttachmentFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  uploadFilesLesson: (lessonId, params, cb) => async dispatch => {
    try {
      dispatch(actions.uploadFilesLessonRequest());
      const { data } = await api.uploadFilesLesson(lessonId, params, cb);
      dispatch(lessonEffects.fetchCourseSectionLesson(lessonId));
      dispatch(actions.uploadFilesLessonSuccess());
      return data;
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Файл не загружен', error: true }),
      );
      dispatch(actions.uploadFilesLessonFailure(error.message));
      return error.message;
    }
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.deleteAttachmentRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.deleteAttachmentSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.deleteAttachmentFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.editAttachmentRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.editAttachmentSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.editAttachmentFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.uploadFilesLessonRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.uploadFilesLessonSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.uploadFilesLessonFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
  },
  initialState,
);

const getState = state => state.files;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

// $FlowFixMe
const selectors = {
  getFiles: cs(s => s.files),
  getLoading: cs(s => s.loading),
};

export { initialState as state, actions, effects, reducer, selectors };
