// @flow
import * as React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
// COMPONENTS
import { AuthRoute, MainRoute } from 'components/Layout/RouteTemplates';

import {
  NotFound,
  // Auth
  LoginPages,

  // Main
  CoursesPages,
  Dashboard,
  ProfilePages,
  ReviewsPages,
  SettingsPages,
  StartupsPages,
  TrackingsPages,
  UsersPages,
} from './routes';

// EXPORTED ROUTES
const Routes = () => (
  <Switch>
    <AuthRoute path="/signin" component={LoginPages} />
    <MainRoute path="/courses" component={CoursesPages} />
    <MainRoute exact path="/dashboard" component={Dashboard} />
    <MainRoute path="/users" component={UsersPages} />
    <MainRoute path="/reviews" component={ReviewsPages} />
    <MainRoute path="/settings" component={SettingsPages} />
    <MainRoute path="/startups" component={StartupsPages} />
    <MainRoute path="/trackings" component={TrackingsPages} />
    <MainRoute path="/profile" component={ProfilePages} />
    <Redirect exact from="/" to="/users" />
    <Route path="/404" component={NotFound} />
    <Redirect to="/404" />
  </Switch>
);

export default Routes;
