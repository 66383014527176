import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import createToast from 'lib/factories/createToast';

const initialState = {
  toasts: [],
};

const actions = createActions('ADD_TOAST', 'REMOVE_TOAST', 'REMOVE_ALL_TOASTS');
// $FixFlowMe
const reducer = handleActions(
  {
    [actions.addToast]: (state, { payload }) => ({
      ...state,
      toasts: [...state.toasts, createToast(payload)],
    }),
    [actions.removeToast]: (state, { payload: id }) => ({
      ...state,
      toasts: state.toasts.filter(toast => toast.id !== id),
    }),
    [actions.removeAllToasts]: () => ({ ...initialState }),
  },
  initialState,
);

const getState = state => state.toasts;
const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getToasts: cs(s => s.toasts),
};

export { initialState as state, reducer, actions, selectors };
