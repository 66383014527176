// @flow
import React from 'react';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

type Props = {
  id: number,
  text: string,
  removeToast: Function,
  error: boolean,
};

const Toast = (props: Props) => {
  const { id, text, removeToast, error } = props;
  const timer = setTimeout(() => removeToast(id), error ? 5000 : 3000);

  return (
    <ToastWrapper
      {...props}
      onClick={() => {
        clearTimeout(timer);
        removeToast(id);
      }}
    >
      <ToastContent>{text}</ToastContent>
      <ToastDismiss type="button">
        <MdClose size="24px" />
      </ToastDismiss>
    </ToastWrapper>
  );
};

export default React.memo(Toast);

const ToastWrapper = styled.li`
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ error, theme }) =>
    error ? theme.colors.pink : theme.colors.blue};
  padding: 16px;
  margin: 0 0 12px;
  opacity: 0.9;
`;

const ToastContent = styled.p`
  flex: 1 1 auto;
  margin: 0 12px 0 0;
  white-space: nowrap;
  overflow: hidden;
`;

const ToastDismiss = styled.button`
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font: inherit;
  padding: 0;
`;
