export default options => ({
  id: null,
  name: '',
  is_active: true,
  is_paid: false,
  description: '',
  content: '',
  price: 0,
  hours: 0,
  executed_count: 0,
  rating: 0,
  course_plan: [''],
  sections: [],
  users: [],
  ...options,
});
