export default options => ({
  id: null,
  name: '',
  description: '',
  tracking_email: '',
  picture: '',
  started_at: '',
  ended_at: '',
  is_closed: true,
  current_week: null,
  count_weeks: null,
  created_at: '',
  startups: [],
  questionary: '',
  ...options,
});
