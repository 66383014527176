// @flow
import * as React from 'react';
import { slice } from 'ramda';
// HOOKS
import { usePagination } from 'lib/hooks';

// TYPES
type Props = {
  items: Array<Object>,
  rest: Object,
};

// EXPORTED HOC
const withPagination = WrappedComponent => ({ items = [], ...rest }: Props) => {
  const {
    itemsPerPage,
    page,
    handlePageNavigation,
    toFirstPage,
  } = usePagination(25);
  const pagesCount = items && Math.ceil(items.length / itemsPerPage);
  const filtered = items
    ? slice((page - 1) * itemsPerPage, page * itemsPerPage, items)
    : [];

  // For Search
  if (!filtered.length && page !== 1) {
    toFirstPage();
  }
  return (
    <WrappedComponent
      {...rest}
      itemsPerPage
      items={filtered}
      pagesCount={pagesCount}
      page={page}
      handlePageNavigation={handlePageNavigation}
    />
  );
};

export default withPagination;
