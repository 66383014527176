import { css, createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { media } from './media';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  #root,
  body,
  html {
    height: 100%;
    min-width: 320px;
  }
  #modal-root {
    position: relative;
    z-index: 1050;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #191919;
  }
  * {
    box-sizing: border-box;
  }
  h1 {
    line-height: 38px;
    font-size: 34px;
    font-weight: 500;
  }
  h2 {
    line-height: 1.4;
    font-size: 48px;
    font-weight: 500;
    margin: 0px 0px 15px;
    ${media.phone`
      font-size: 24px;
    `}
  }
  h3 {
    font-size: 36px;
    line-height: 1.3;
    font-weight: 500;
    margin: 0px 0px 15px;
    ${media.phone`
      font-size: 30px;
    `}
  }
  h4 {
    font-size: 24px;
    line-height: 120%;
    font-weight: 500;
    margin: 0px 0px 15px;
    ${media.phone`
      font-size: 18px;
    `}
  }
  p {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    margin: 0px 0px 20px;
  }
  button {
    font-weight: 300;
    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 0.8
    }
  }
  a {
    line-height: 22px;
    transition: all 0.2 ease;
    color: initial;
    text-decoration: none;
    transition: color 0.3s;
    &:hover {
      opacity: 0.8
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Scrollbar = css`
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.pink};
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: 0.8;
  }
`;

export const Container = css`
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 25px;
`;

export const ResetDefaultButton = css`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;
export const DefaultSection = css`
  margin-bottom: 15px;
  padding: 55px 46px 50px;
  background: #fff;
  p {
    color: #ababab;
  }
  ${media.phone`
    padding: 20px 20px 35px;
  `}
`;