import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const initialState = {
  modal: { type: null, open: false, args: null },
};

const actions = createActions('OPEN_MODAL', 'CLOSE__MODAL', 'CLOSE_ALL_MODALS');
// $FixFlowMe
const reducer = handleActions(
  {
    [actions.openModal]: (state, { payload: { type, args } }) => ({
      ...state,
      modal: { type, open: true, args },
    }),
    [actions.closeModal]: state => ({
      ...state,
      modal: initialState.modal,
    }),
    [actions.closeAllModals]: () => ({ ...initialState }),
  },
  initialState,
);

const getState = state => state.modals;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getModal: cs(s => s.modal),
  getType: cs(s => s.modal.type),
  getOpen: cs(s => s.modal.open),
  getArgs: cs(s => s.modal.args),
};

export { initialState as state, reducer, actions, selectors };
