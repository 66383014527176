// / @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import styled, { ReactComponentStyled } from 'styled-components';
import { MdCancel, MdClose, MdPerson } from 'react-icons/md';
// STYLES
import { media, Scrollbar } from 'lib/styles';
// CONSTANTS
import sidebarItems from 'lib/constants/sidebarItems';
// DUCKS
import {
  effects as adminEffects,
  selectors as adminSelectors,
} from 'redux/ducks/common/admin.duck';
// TYPES
import { Profile } from 'lib/types';
// COMPONENTS
import Divider from 'components/UI/Divider';
import SidebarPortal from 'components/Layout/Sidebars/SidebarPortal';
import MenuItem from './MenuItem';

// TYPES
type Props = {
  isSidebarOpen: boolean,
  profile: Profile,
  authLogout: Function,
  closeSidebar: Function,
  handleCloseSidebar: Function,
  isEditor: boolean,
};

// EXPORTED COMPONENT
const Sidebar = ({
  isSidebarOpen,
  authLogout,
  closeSidebar,
  profile,
  handleCloseSidebar,
  isEditor,
  ...rest
}: Props) => {
  return (
    <>
      <SidebarPortal>
        <Content isOpen={isSidebarOpen} {...rest} id="sidebar">
          <MediaHeader>
            {profile.avatar ? <Avatar src={profile.avatar} /> : <StyledIcon />}
            <span>{`${profile.name} ${profile.last_name}`}</span>
            <MdClose size="30px" onClick={handleCloseSidebar} />
          </MediaHeader>
          {sidebarItems
            .filter(({ id }) => !(isEditor && id !== 5))
            .map(({ id, ...itemRest }) => (
              <MenuItem key={id} {...itemRest} />
            ))}
          <Divider />
          <MenuItem to="/profile" icon={<MdPerson />} linkName="Профиль" />
          <MenuItem
            to="#"
            icon={<MdCancel />}
            onClick={authLogout}
            linkName="Выйти"
          />
        </Content>
        <ContainerBg isOpen={isSidebarOpen} onClick={handleCloseSidebar} />
      </SidebarPortal>
    </>
  );
};

export default compose(
  connect(
    state => ({
      profile: adminSelectors.getInfoProfile(state),
      isLoading: adminSelectors.getLoading(state),
      isEditor: adminSelectors.isEditor(state),
    }),
    { ...adminEffects },
  ),
  withRouter,
)(Sidebar);

const Content: ReactComponentStyled<{
  isOpen: boolean,
}> = styled.div`
  width: 290px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  /* opacity: ${({ isOpen }) => (!isOpen ? '0' : '1')}; */
  transform: ${({ isOpen }) =>
    !isOpen ? 'translateX(-290px)' : 'translateX(0)'};
  transition: all 0.5s;
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  align-content: start;
  grid-gap: 24px;
  padding: 16px;
  padding-bottom: 40px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  ${Scrollbar};
`;

const ContainerBg: ReactComponentStyled<{
  isOpen: boolean,
}> = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  background-color: ${({ theme }) => theme.colors.transparentBlack70};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const MediaHeader = styled.div`
  display: none;
  ${media.tablet`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  `};
  > span {
    line-height: 1.4;
  }
  > svg {
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }

  > svg:last-child {
    justify-self: end;
  }
`;

const Avatar = styled.img`
  width: 60px;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
`;

const StyledIcon = styled(MdPerson)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  color: ${({ theme }) => theme.colors.lightGrey};
`;
