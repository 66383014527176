// @flow
import * as React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';

const Divider = () => <Content />;
export default Divider;

const Content: ReactComponentStyled<{}> = styled.div`
  height: 0.95px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;
