// @flow
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

export const CoursesPages = loadable(() =>
  pMinDelay(import('./CoursesPages'), 300),
);
export const Dashboard = loadable(() => pMinDelay(import('./Dashboard'), 300));
export const ProfilePages = loadable(() =>
  pMinDelay(import('./ProfilePages'), 300),
);
export const ReviewsPages = loadable(() =>
  pMinDelay(import('./ReviewsPages'), 300),
);
export const SettingsPages = loadable(() =>
  pMinDelay(import('./SettingsPages'), 300),
);
export const StartupsPages = loadable(() =>
  pMinDelay(import('./StartupsPages'), 300),
);
export const TrackingsPages = loadable(() =>
  pMinDelay(import('./TrackingsPages'), 300),
);
export const UsersPages = loadable(() =>
  pMinDelay(import('./UsersPages'), 300),
);
