// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MdAccountCircle, MdCancel } from 'react-icons/md';
// COMPONENTS
import Divider from 'components/UI/Divider';

type Props = {
  authLogout: Function,
};

const DropdownHeader = ({ authLogout }: Props) => (
  <Content>
    <StyledLink to='/profile'>
      <MdAccountCircle size="20" />
      <span>Профиль</span>
    </StyledLink>
    <Divider />
    <StyledLink to="#" onClick={authLogout}>
      <MdCancel size="20" />
      <span>Выход</span>
    </StyledLink>
  </Content>
);

export default React.memo(DropdownHeader);

const Content = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 2;
  width: 175px;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  background-color: ${({ theme }) => theme.colors.white};

  a:last-of-type svg {
    transform: rotate(180deg);
  }
`;
const StyledLink = styled(Link)`
  display: grid;
  align-items: center;
  grid-gap: 10px;
  justify-content: start;
  grid-auto-flow: column;
  padding: 13px;
  color: ${({ theme }) => theme.colors.black};

  svg {
    color: ${({ theme }) => theme.colors.pink};
  }
  :active,
  :focus {
    text-decoration: none;
  }
  :hover {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;
