// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
// HOOKS
import { useSetGlobalEventHandler } from 'lib/hooks';
// DUCKS
import { actions, selectors } from 'redux/ducks/common/modals.duck';

const { useEffect } = React;

// TYPES
type Props = Object;
// EXPORTED HOC
const withModalState = WrappedComponent => (props: Props) => {
  const { open, closeModal } = props;
  const onEscKeyPressed = event => {
    if (event.keyCode === 27 && open) {
      closeModal();
      return null;
    }
    return null;
  };

  useEffect(() => {
    if (open) {
      document.querySelector('body').style = 'overflow: hidden';
    }
    if (!open) {
      document.querySelector('body').removeAttribute('style');
    }
  }, [open]);

  useSetGlobalEventHandler(window, 'keydown', onEscKeyPressed, open);
  return <WrappedComponent {...props} />;
};

export default compose(
  connect(
    state => ({
      open: selectors.getOpen(state),
      args: selectors.getArgs(state),
      type: selectors.getType(state),
    }),
    { ...actions },
  ),
  withModalState,
);
