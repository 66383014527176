// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'lib/history';
// API
import api from 'lib/api';
// FACTORIES
import createLesson from 'lib/factories/createLesson';
// DUCKS
import { effects as courseEffects } from './course.duck';
import { effects as testEffects, actions as testActions } from './test.duck';
import { actions as toastsActions } from './common/toasts.duck';
// INITIAL STATE
const initialState = {
  lesson: createLesson(),
  loading: false,
  errorMessage: null,
};
// ACTIONS
const actions = createActions(
  'FETCH_COURSE_SECTION_LESSON_REQUEST',
  'FETCH_COURSE_SECTION_LESSON_SUCCESS',
  'FETCH_COURSE_SECTION_LESSON_FAILURE',

  'CREATE_COURSE_SECTION_LESSON_REQUEST',
  'CREATE_COURSE_SECTION_LESSON_SUCCESS',
  'CREATE_COURSE_SECTION_LESSON_FAILURE',

  'UPDATE_COURSE_SECTION_LESSON_REQUEST',
  'UPDATE_COURSE_SECTION_LESSON_SUCCESS',
  'UPDATE_COURSE_SECTION_LESSON_FAILURE',

  'REMOVE_COURSE_SECTION_LESSON_REQUEST',
  'REMOVE_COURSE_SECTION_LESSON_SUCCESS',
  'REMOVE_COURSE_SECTION_LESSON_FAILURE',
);
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchCourseSectionLessonRequest]: state => ({
      ...state,
      loading: true,
      errorMessage: null,
    }),
    [actions.fetchCourseSectionLessonSuccess]: (
      state,
      { payload: lesson },
    ) => ({
      ...state,
      lesson,
      loading: false,
    }),
    [actions.fetchCourseSectionLessonFailure]: (
      state,
      { payload: errorMessage },
    ) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.createCourseSectionLessonRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.createCourseSectionLessonSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createCourseSectionLessonFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.updateCourseSectionLessonRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.updateCourseSectionLessonSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateCourseSectionLessonFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.removeCourseSectionLessonRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.removeCourseSectionLessonSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.removeCourseSectionLessonFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  fetchCourseSectionLesson: id => async dispatch => {
    try {
      await dispatch(actions.fetchCourseSectionLessonRequest());
      const { data, meta } = await api.fetchCourseSectionLesson(id);
      if (meta.success) {
        await dispatch(actions.fetchCourseSectionLessonSuccess(data));
        if (data.test) await dispatch(testEffects.fetchTest(data.test.id));
        else await dispatch(testActions.clearTest());
      }
      return data;
    } catch (error) {
      dispatch(actions.fetchCourseSectionLessonFailure(error.message));
      dispatch(toastsActions.addToast({ text: 'Урок не найден', error: true }));
      history.goBack();
      return new Promise(resolve => resolve(error.message));
    }
  },
  createCourseSectionLesson: payload => async dispatch => {
    try {
      await dispatch(actions.createCourseSectionLessonRequest());
      const { meta } = await api.createCourseSectionLesson(payload);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Урок создан' }));
        await dispatch(actions.createCourseSectionLessonSuccess());
        await dispatch(courseEffects.fetchCourse(payload.course_id));
      }
      return true;
    } catch (error) {
      dispatch(toastsActions.addToast({ text: 'Урок не создан', error: true }));
      await dispatch(actions.createCourseSectionLessonFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  updateCourseSectionLesson: payload => async dispatch => {
    try {
      await dispatch(actions.updateCourseSectionLessonRequest());
      const { meta } = await api.updateCourseSectionLesson(payload);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Урок сохранён' }));
        await dispatch(actions.updateCourseSectionLessonSuccess());
        await dispatch(courseEffects.fetchCourse(payload.course_id));
        await dispatch(effects.fetchCourseSectionLesson(payload.id));
      }
      return true;
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Урок не сохранён', error: true }),
      );
      await dispatch(actions.updateCourseSectionLessonFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  removeCourseSectionLesson: payload => async dispatch => {
    try {
      await dispatch(actions.removeCourseSectionLessonRequest());
      const { meta } = await api.removeCourseSectionLesson(payload.id);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Урок удалён' }));
        await dispatch(actions.removeCourseSectionLessonSuccess());
        await dispatch(courseEffects.fetchCourse(payload.course_id));
        return true;
      }
      return true;
    } catch (error) {
      dispatch(toastsActions.addToast({ text: 'Урок не удалён', error: true }));
      await dispatch(actions.removeCourseSectionLessonFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
};

const getState = state => state.lesson;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

// $FlowFixMe
const selectors = {
  getLesson: cs(s => s.lesson),
  getLoading: cs(s => s.loading),
};

export { initialState as state, actions, effects, reducer, selectors };
