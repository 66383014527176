// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'lib/history';
import { pipe, assoc, prop, join } from 'ramda';
import api from 'lib/api';
// FACTORIES
import createStartup from 'lib/factories/createStartup';
// INITIAL STATE
const initialState = {
  startup: createStartup(),
  startupPdf: '',
  startupZip: '',
  loading: false,
  errorMessage: null,
};
// ACTIONS
const actions = createActions(
  'FETCH_STARTUP_REQUEST',
  'FETCH_STARTUP_SUCCESS',
  'FETCH_STARTUP_FAILURE',

  'CREATE_STARTUP_REQUEST',
  'CREATE_STARTUP_SUCCESS',
  'CREATE_STARTUP_FAILURE',

  'UPDATE_STARTUP_REQUEST',
  'UPDATE_STARTUP_SUCCESS',
  'UPDATE_STARTUP_FAILURE',

  'FETCH_STARTUP_PDF_REQUEST',
  'FETCH_STARTUP_PDF_SUCCESS',
  'FETCH_STARTUP_PDF_FAILURE',

  'FETCH_STARTUP_ZIP_REQUEST',
  'FETCH_STARTUP_ZIP_SUCCESS',
  'FETCH_STARTUP_ZIP_FAILURE',
);

const effects = {
  // $FlowFixMe
  fetchStartup: id => async dispatch => {
    try {
      await dispatch(actions.fetchStartupRequest());
      const { data, meta } = await api.getStartup(id);
      if (meta.success) {
        await dispatch(actions.fetchStartupSuccess(data));
      }
      return meta.success;
    } catch (error) {
      dispatch(actions.fetchStartupFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  // $FlowFixMe
  createStartup: payload => async dispatch => {
    try {
      await api.createStartup(payload);
      await dispatch(actions.createStartupSuccess());
      history.push('/startups');
      return true;
    } catch (error) {
      await dispatch(actions.createStartupFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  updateStartup: payload => async dispatch => {
    try {
      await api.updateStartup(payload);
      await dispatch(actions.updateStartupSuccess());
      history.push('/startups');
      return true;
    } catch (error) {
      await dispatch(actions.updateStartupFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  fetchStartupPdf: ({ trackingId, startupId }) => async dispatch => {
    dispatch(actions.fetchStartupPdfRequest());
    try {
      const { file } = await api.getStartupPdf(trackingId, startupId);
      if (file) {
        dispatch(actions.fetchStartupPdfSuccess(file));
      }
      return true;
    } catch (error) {
      if (error.status && error.status === 404) {
        dispatch(actions.fetchStartupZipFailure('Нет файлов для выгрузки'));
      }
      return new Promise(resolve => resolve(error));
    }
  },
  fetchStartupZip: ({ trackingId, startupId }) => async dispatch => {
    dispatch(actions.fetchStartupZipRequest());
    try {
      const { file } = await api.getStartupZip(trackingId, startupId);
      if (file) {
        dispatch(actions.fetchStartupZipSuccess(file));
      }
      return true;
    } catch (error) {
      if (error.status && error.status === 404) {
        dispatch(actions.fetchStartupZipFailure('Нет файлов для выгрузки'));
      }
      return new Promise(resolve => resolve(error));
    }
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchStartupRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchStartupSuccess]: (state, { payload: startup }) => ({
      ...state,
      startup,
      loading: false,
    }),
    [actions.fetchStartupFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.updateStartupRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.updateStartupSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateStartupFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.fetchStartupPdfRequest]: state => ({
      ...state,
      startupPdf: '',
      loading: true,
      errorMessage: null,
    }),
    [actions.fetchStartupPdfSuccess]: (state, { payload: startupPdf }) => ({
      ...state,
      startupPdf,
      loading: false,
      errorMessage: null,
    }),
    [actions.fetchStartupPdfFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    }),
    [actions.fetchStartupZipRequest]: state => ({
      ...state,
      startupZip: '',
      loading: true,
      errorMessage: null,
    }),
    [actions.fetchStartupZipSuccess]: (state, { payload: startupZip }) => ({
      ...state,
      startupZip,
      loading: false,
      errorMessage: null,
    }),
    [actions.fetchStartupZipFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    }),
  },
  initialState,
);

const getState = state => state.startup;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

// $FlowFixMe
const selectors = {
  getStartup: cs(
    s =>
      s.startup &&
      pipe(e =>
        assoc(
          'ownerName',
          join(' ', [prop('name', e.user), prop('last_name', e.user)]),
          e,
        ),
      )(s.startup),
  ),
  getLoading: createSelector(
    [getState],
    s => s.loading,
  ),
  getStartupPdf: createSelector(
    [getState],
    s => s.startupPdf,
  ),
  getStartupZip: createSelector(
    [getState],
    s => s.startupZip,
  ),
  getError: createSelector(
    [getState],
    s => s.errorMessage,
  ),
};

export { initialState as state, actions, effects, reducer, selectors };
