import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'lib/history';
// API
import api from 'lib/api';

const initialState = {
  profile: null,
  error: false,
  loading: false,
};

const actions = createActions(
  'FETCH_PROFILE_REQUEST',
  'FETCH_PROFILE_SUCCESS',
  'FETCH_PROFILE_FAILURE',

  'UPDATE_PROFILE_REQUEST',
  'UPDATE_PROFILE_SUCCESS',
  'UPDATE_PROFILE_FAILURE',

  'UPDATE_PROFILE_PASS_REQUEST',
  'UPDATE_PROFILE_PASS_SUCCESS',
  'UPDATE_PROFILE_PASS_FAILURE',
);

const reducer = handleActions(
  {
    [actions.fetchProfileSuccess]: (state, { payload }) => ({
      ...state,
      profile: payload,
      loading: false,
    }),
    [actions.fetchProfileFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),

    [actions.updateProfileRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.updateProfileSuccess]: (state, { payload }) => ({
      ...state,
      profile: payload,
      loading: false,
    }),
    [actions.updateProfileFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.updateProfilePassRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.updateProfilePassSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateProfilePassFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  fetchProfile: () => async dispatch => {
    dispatch(actions.fetchProfileRequest());
    try {
      const { data } = await api.getProfile();
      await dispatch(actions.fetchProfileSuccess(data));
    } catch (error) {
      dispatch(actions.fetchProfileFailure(error));
      return new Promise(resolve => resolve(error));
    }
    return true;
  },
  // $FlowFixMe
  updateProfile: payload => async dispatch => {
    dispatch(actions.updateProfileRequest());
    try {
      await api.updateUser(payload);
      await dispatch(actions.updateProfileSuccess());
      await dispatch(effects.fetchProfile());
      history.push('/users');
      return true;
    } catch (error) {
      await dispatch(actions.updateProfileFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  updateProfilePass: payload => async dispatch => {
    try {
      const {
        data: { meta, data },
      } = await api.updatePassword(payload);

      if (meta.success) {
        await Promise.all([
          dispatch(actions.updatePassProfileSuccess(data)),
          dispatch(actions.fetchProfile()),
        ]);
      }
      return true;
    } catch (error) {
      await dispatch(actions.updatePassProfileFailure(error));
      return new Promise(resolve => resolve(error));
    }
  },
};

const getState = state => state.admin;
const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getInfoProfile: cs(s => s.profile),
  getRole: cs(
    s => s.profile && s.profile.roles.length && s.profile.roles[0].slug,
  ),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
  isEditor: cs(
    s =>
      s.profile &&
      s.profile.roles.length === 1 &&
      s.profile.roles[0].slug === 'editor',
  ),
};

export { initialState as state, reducer, actions, selectors, effects };
