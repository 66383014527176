// @flow
import * as React from 'react';
import { createPortal } from 'react-dom';
// TYPES
type Props = {
  children: React.Node,
  modalRoot: HTMLElement,
};

// EXPORTED COMPONENT
const MainPortal = ({ children, modalRoot }: Props) =>
  createPortal(children, modalRoot);

MainPortal.defaultProps = {
  modalRoot: document.getElementById('modal-root'),
};

export default MainPortal;
