// @flow
import { useEffect } from "react";

export const useSetGlobalEventHandler = (globalTarget, eventName, f, cond)  => useEffect(() => {
  if (globalTarget && eventName && f) {
    globalTarget.addEventListener(eventName, f);
    return () => {
      globalTarget.removeEventListener(eventName, f);
    };
  } 
}, [cond]);
