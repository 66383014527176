import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import moment from 'moment';
import { pipe, assoc, map, find, propEq } from 'ramda';
// API
import api from 'lib/api';

import 'moment/locale/ru';

const initialState = {
  trackings: [],
  error: false,
  loading: false,
};

const actions = createActions(
  'FETCH_TRACKINGS_REQUEST',
  'FETCH_TRACKINGS_SUCCESS',
  'FETCH_TRACKINGS_FAILURE',
);

const reducer = handleActions(
  {
    [actions.fetchTrackingsRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.fetchTrackingsSuccess]: (state, { payload }) => ({
      ...state,
      trackings: payload,
      loading: false,
    }),
    [actions.fetchTrackingsFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),

    [actions.trackingAddStartupRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.trackingAddStartupSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.trackingAddStartupFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  fetchTrackings: () => async dispatch => {
    try {
      dispatch(actions.fetchTrackingsRequest());
      const { data, meta } = await api.getTrackings();
      if (meta.success) {
        dispatch(actions.fetchTrackingsSuccess(data));
      }
    } catch (error) {
      dispatch(actions.fetchTrackingsFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
    return true;
  },
};

const getState = state => state.trackings;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getTrackings: cs(
    s =>
      s.trackings &&
      // s.trackings >= 0 &&
      map(
        pipe(
          e =>
            assoc(
              'started_at',
              e.started_at &&
                moment(Date.parse(e.started_at)).format('DD.MM.YYYY'),
              e,
            ),
          e =>
            assoc(
              'ended_at',
              e.ended_at && moment(Date.parse(e.ended_at)).format('DD.MM.YYYY'),
              e,
            ),
        ),
      )(s.trackings),
  ),
  // $FlowFixMe
  getTrackingById: cs(
    (s, id) => s.trackings && find(propEq('id', id), s.trackings),
  ),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
};

export { initialState as state, reducer, actions, effects, selectors };
