// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// DUCKS
// API
import api from 'lib/api';
// LIB
import history from 'lib/history';
import { effects as anketasEffects } from './anketas.duck';

// INITIAL STATE
const initialState = {
  anketa: {},
  loading: false,
  loaded: false,
  creating: false,
  created: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  errorMessage: null,
};

// ACTIONS
const actions = createActions(
  'FETCH_ANKETA_REQUEST',
  'FETCH_ANKETA_SUCCESS',
  'FETCH_ANKETA_FAILURE',
  'CREATE_ANKETA_REQUEST',
  'CREATE_ANKETA_SUCCESS',
  'CREATE_ANKETA_FAILURE',
  'UPDATE_ANKETA_REQUEST',
  'UPDATE_ANKETA_SUCCESS',
  'UPDATE_ANKETA_FAILURE',
  'DELETE_ANKETA_REQUEST',
  'DELETE_ANKETA_SUCCESS',
  'DELETE_ANKETA_FAILURE',
);

// REDUCERS
const reducer = handleActions(
  {
    [actions.fetchAnketaRequest]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [actions.fetchAnketaSuccess]: (state, { payload: anketa }) => ({
      ...state,
      anketa,
      loading: false,
      loaded: true,
    }),
    [actions.fetchAnketaFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
      loaded: false,
    }),
    [actions.createAnketaRequest]: state => ({
      ...state,
      creating: true,
      created: false,
    }),
    [actions.createAnketaSuccess]: state => ({
      ...state,
      creating: false,
      created: true,
    }),
    [actions.createAnketaFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      creating: false,
      created: false,
    }),
    [actions.updateAnketaRequest]: state => ({
      ...state,
      updating: true,
      updated: false,
    }),
    [actions.updateAnketaSuccess]: state => ({
      ...state,
      updating: false,
      updated: true,
    }),
    [actions.updateAnketaFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      updating: false,
      updated: false,
    }),
    [actions.deleteAnketaRequest]: state => ({
      ...state,
      deleting: true,
      deleted: false,
    }),
    [actions.deleteAnketaSuccess]: state => ({
      ...state,
      deleting: false,
      deleted: true,
    }),
    [actions.deleteAnketaFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      deleting: false,
      deleted: false,
    }),
  },
  initialState,
);

const effects = {
  fetchAnketa: anketaId => async dispatch => {
    dispatch(actions.fetchAnketaRequest());
    try {
      const { data, meta } = await api.getAnketa(anketaId);
      if (meta.success) {
        dispatch(actions.fetchAnketaSuccess(data));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchAnketaFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  createAnketa: data => async dispatch => {
    dispatch(actions.createAnketaRequest());
    try {
      const { meta } = await api.createAnketa(data);
      if (meta.success) {
        dispatch(actions.createAnketaSuccess());
        history.push('/trackings');
      }
      return true;
    } catch (error) {
      dispatch(actions.createAnketaFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  updateAnketa: ({ anketaId, data }) => async dispatch => {
    dispatch(actions.updateAnketaRequest());
    try {
      const { meta } = await api.updateAnketa(anketaId, data);
      if (meta.success) {
        dispatch(actions.updateAnketaSuccess());
        history.push('/trackings');
      }
      return true;
    } catch (error) {
      dispatch(actions.updateAnketaFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  deleteAnketa: anketaId => async dispatch => {
    dispatch(actions.deleteAnketaRequest());
    try {
      const { meta } = await api.deleteAnketa(anketaId);
      if (meta.success) {
        dispatch(actions.deleteAnketaSuccess());
        dispatch(anketasEffects.fetchAnketas());
      }
      return true;
    } catch (error) {
      dispatch(actions.deleteAnketaFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
};

const getState = state => state.anketa;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getAnketa: cs(s => s.anketa),
  getLoading: cs(s => s.loading),
  getLoaded: cs(s => s.loaded),
  getCreating: cs(s => s.creating),
  getCreated: cs(s => s.created),
  getUpdating: cs(s => s.updating),
  getUpdated: cs(s => s.updated),
  getDeleting: cs(s => s.deleting),
  getDeleted: cs(s => s.deleted),
  getErrors: cs(s => s.errorMessage),
};

export { initialState as state, actions, effects, reducer, selectors };
