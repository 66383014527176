// @flow
import * as React from 'react';
import styled, { type ReactComponentStyled } from 'styled-components';
import Spinner from 'react-spinkit';
// STYLES
import { primaryTheme } from 'lib/styles';

type Props = {
  height?: string,
  circleSize?: string,
};

const Loader = (props: Props) => (
  <SpinnerContainer {...props}>
    <Spinner fadeIn="none" name="circle" color={primaryTheme.colors.pink} />
  </SpinnerContainer>
);
export default Loader;

Loader.defaultProps = {
  height: '100',
  circleSize: '100',
};

const SpinnerContainer: ReactComponentStyled<Props> = styled.section`
  display: grid;
  width: 100%;
  align-items: center;
  justify-items: center;
  height: ${({ height }) => `${height}px`};
  .sk-circle {
    width: ${({ circleSize }) => `${circleSize}px`};
    height: ${({ circleSize }) => `${circleSize}px`};
  }
  .sk-circle > div {
    width: ${({ circleSize }) => `${circleSize}px`};
    height: ${({ circleSize }) => `${circleSize}px`};
  }
`;
