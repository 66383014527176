// @flow
import * as React from 'react';
import { render } from 'react-dom';
// COMPONENTS
import App from './App';

const renderApp = Application => {
  render(<Application />, document.getElementById('root'));
};

renderApp(App);

/* eslint-disable global-require */
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}
/* eslint-disable global-require */
