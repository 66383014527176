// / @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import styled from 'styled-components';
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdMenu,
  MdPerson,
} from 'react-icons/md';
// ASSETS
import mainLogo from 'assets/icons/itpark-logo_small.png';
// DUCKS
import { effects as authEffects } from 'redux/ducks/common/auth.duck';
import {
  effects as adminEffects,
  selectors as adminSelectors,
} from 'redux/ducks/common/admin.duck';
// STYLES
import { media } from 'lib/styles';
// HOOKS
import { useSetGlobalEventHandler } from 'lib/hooks';
// TYPES
import { Profile } from 'lib/types';
// CONSTANTS
import sidebarItems from 'lib/constants/sidebarItems';
// COMPONENTS
import DropdownHeader from 'components/UI/DropdownHeader';
import MenuItem from './MenuItem';

type Props = {
  profile: Profile,
  authLogout: Function,
  handleOpenSidebar: Function,
  isEditor: boolean,
};

const Header = (props: Props) => {
  const [dropdownVisible, toggleDropdown] = useState(false);
  const { handleOpenSidebar, profile, isEditor } = props;

  const handleHideDropdown = e => {
    if (e.target.nodeName === 'svg') return;
    toggleDropdown(false);
  };

  useSetGlobalEventHandler(document, 'click', handleHideDropdown);

  const setDropdownVisible = () =>
    toggleDropdown(prevDropdownVisible => !prevDropdownVisible);

  return (
    <StyledHeaderLayout>
      <HeaderRow>
        <Link to="/">
          <LogoImg alt="logo" src={mainLogo} />
        </Link>
        {isEditor ? (
          <LinksRight>
            {sidebarItems
              .slice(1)
              .filter(({ id }) => id === 5)
              .map(({ id, ...itemRest }) => (
                <MenuItem key={id} {...itemRest} />
              ))}
          </LinksRight>
        ) : (
          <Links columns={sidebarItems.length - 1}>
            {sidebarItems.slice(1).map(({ id, ...itemRest }) => (
              <MenuItem key={id} {...itemRest} />
            ))}
          </Links>
        )}
        {/* </Links> */}
        {dropdownVisible && <DropdownHeader {...props} />}
        <Account>
          {profile.avatar ? <Avatar src={profile.avatar} /> : <StyledIcon />}
          <UserBox>
            {dropdownVisible ? (
              <MdKeyboardArrowUp size="24" onClick={setDropdownVisible} />
            ) : (
              <MdKeyboardArrowDown size="24" onClick={setDropdownVisible} />
            )}
          </UserBox>
        </Account>
        <HamburgerBlock onClick={handleOpenSidebar}>
          <MdMenu size="32px" />
        </HamburgerBlock>
      </HeaderRow>
    </StyledHeaderLayout>
  );
};

export default compose(
  connect(
    state => ({
      profile: adminSelectors.getInfoProfile(state),
      isLoading: adminSelectors.getLoading(state),
      isEditor: adminSelectors.isEditor(state),
    }),
    { ...adminEffects, ...authEffects },
  ),
  withRouter,
)(Header);

const StyledHeaderLayout = styled.div`
  padding: 40px 0;
  padding-left: 20px;
  ${media.tablet`
    width: 100%;
    padding: 20px 0 20px 20px;
  `};
  ${media.phone`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0 10px 10px;
  `};
`;

const HeaderRow = styled.div`
  position: relative;
  width: 1130px;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  ${media.laptop`
    width: 100%;
  `};
  ${media.tablet`
    grid-template-columns: 1fr 42px;
  `};

  > a {
    width: max-content;
  }
`;

const UserBox = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 20px;
  cursor: pointer;
`;

const Account = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
  ${media.tablet`
    display: none;
  `};
`;

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.white};
`;

const HamburgerBlock = styled.div`
  display: none;
  cursor: pointer;
  ${media.tablet`
    display: grid;
    margin-right: 20px;
  `};
  svg {
    color: ${({ theme }) => theme.colors.white};
  }

  :hover,
  :active {
    opacity: 0.8;
  }
`;

const LogoImg = styled.img`
  max-width: 60px;
  height: 60px;
`;

const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 1}, auto);
  grid-gap: 33px;
  align-items: center;
  justify-items: start;
  margin: 0 36px;
  ${media.laptop`
    grid-gap: 20px;
  `};
  ${media.tablet`
    display: none;
  `};
`;

const LinksRight = styled(Links)`
  justify-self: flex-end;
`;

const StyledIcon = styled(MdPerson)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  color: ${({ theme }) => theme.colors.lightGrey};
`;
