// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'lib/history';
// API
import api from 'lib/api';
// FACTORIES
import createReview from 'lib/factories/createReview';
// DUCKS
import { actions as toastsActions } from 'redux/ducks/common/toasts.duck';
// INITIAL STATE
const initialState = {
  review: createReview(),
  loading: false,
  errorMessage: null,
};
// ACTIONS
const actions = createActions(
  'FETCH_REVIEW_REQUEST',
  'FETCH_REVIEW_SUCCESS',
  'FETCH_REVIEW_FAILURE',

  'CREATE_REVIEW_REQUEST',
  'CREATE_REVIEW_SUCCESS',
  'CREATE_REVIEW_FAILURE',

  'UPDATE_REVIEW_REQUEST',
  'UPDATE_REVIEW_SUCCESS',
  'UPDATE_REVIEW_FAILURE',

  'REMOVE_REVIEW_REQUEST',
  'REMOVE_REVIEW_SUCCESS',
  'REMOVE_REVIEW_FAILURE',
);

const effects = {
  // $FlowFixMe
  fetchReview: id => async dispatch => {
    try {
      await dispatch(actions.fetchReviewRequest());
      const { data, meta } = await api.getReview(id);
      if (meta.success) {
        await dispatch(actions.fetchReviewSuccess(data));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchReviewFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  // $FlowFixMe
  createReview: payload => async dispatch => {
    try {
      await api.createReview(payload);
      await dispatch(actions.createReviewSuccess());
      history.push('/reviews');
      return true;
    } catch (error) {
      await dispatch(actions.createReviewFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  updateReview: payload => async dispatch => {
    try {
      await api.updateReview(payload);
      await dispatch(actions.updateReviewSuccess());
      history.push('/reviews');
      return true;
    } catch (error) {
      await dispatch(actions.updateReviewFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  removeReview: id => async dispatch => {
    dispatch(actions.updateReviewRequest());
    try {
      const { meta } = await api.deleteReview(id);
      if (meta.success) {
        dispatch(actions.removeReviewSuccess());
        dispatch(toastsActions.addToast({ text: 'Отзыв удалён' }));
        history.push('/reviews');
      }
      return meta.success;
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Отзыв не удалён', error: true }),
      );
      dispatch(actions.removeReviewFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchReviewRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchReviewSuccess]: (state, { payload: review }) => ({
      ...state,
      review,
      loading: false,
    }),
    [actions.fetchReviewFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.updateReviewRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.updateReviewSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateReviewFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.createReviewSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createReviewFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.removeReviewRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.removeReviewSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.removeReviewFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const getState = state => state.review;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

// $FlowFixMe
const selectors = {
  getReview: cs(s => s.review),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
};

export { initialState as state, actions, effects, reducer, selectors };
