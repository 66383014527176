// @flow
import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
// STYLES
import { media, Container } from 'lib/styles';
// ICONS
import logoSmall from 'assets/icons/itpark-logo_small.svg';
import logoDefault from 'assets/icons/itpark-logo_default.svg';
// COMPONENTS
import { Button } from 'components/UI/Buttons';
// TYPES
type Props = {
  history: Object,
};
// EXPORTED COMPONENT
function NotFound({ history }: Props) {
  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];
  const trans1 = (x, y) => `translate3d(${x / 40}px,${y / 40}px,0)`;
  const trans2 = (x, y) => `translate3d(${x / 12}px,${y / 12}px,0)`;
  const trans3 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
  const trans4 = (x, y) => `translate3d(${x / 4}px,${y / 4}px,0)`;

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));
  // eslint-disable-next-line
  const { xy } = props;

  return (
    <Content
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <LeftBlueEllipse />
      <LeftBottomLine />
      <LeftBottomRadius style={{ transform: xy.interpolate(trans3) }} />
      <LeftBottomRedEllipse />
      <RightBottomBlueEllipse />
      <RightBlueLine />
      <RightCenterBlueRadius style={{ transform: xy.interpolate(trans2) }} />
      <MainContainer>
        <TopRedEllipse />
        <Logos>
          <img src={logoSmall} alt="лого" />
          <img src={logoDefault} alt="лого" />
        </Logos>
        <TextContent>
          <strong>404</strong>
          <span>Страница не найдена</span>
          <Button
            name="На главную"
            buttonColor="pink"
            onClick={() => history.push('/')}
          />
          <TopInnerRadius style={{ transform: xy.interpolate(trans4) }} />
          <BottomInnerRadius style={{ transform: xy.interpolate(trans1) }} />
        </TextContent>
      </MainContainer>
    </Content>
  );
}

export default NotFound;

const Content = styled.div`
  position: relative;
  overflow: hidden;
`;

const MainContainer = styled.div`
  ${Container};
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  ${media.phone`
    width: 100%;
    grid-template-columns: 100%;
    text-align: center;
  `}
`;

const Logos = styled.div`
  margin-top: 57px;
  left: 25px;
  top: 0;
  position: absolute;
  display: grid;
  align-items: center;
  grid-gap: 0 9px;
  grid-template-columns: 42px 103px;
  justify-content: start;
  & > img {
    max-width: 100%;
    height: auto;
  }
  ${media.phone`
    margin-top: 30px;
    grid-template-columns: 28px 61px;
    grid-gap: 0 6px;
  `}
`;

const LeftBlueEllipse = styled(animated.div)`
  border-radius: 50%;
  border: 19.5px solid ${({ theme }) => theme.colors.blue};
  width: 46vh;
  height: 46vh;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-60%, -50%);

  ${media.desktop`
    width: 32vh;
    height: 32vh;
    border-width: 13px;
    transform: translate(-70%, -50%);
  `}

  ${media.phone`
    width: 100px;
    height: 100px;
    top: 107px;
    transform: translateX(-40%);
    border-width: 6px;
  `}
`;

const TopRedEllipse = styled(animated.div)`
  border-radius: 50%;
  border: 7.8px solid ${({ theme }) => theme.colors.pink};
  width: 24vh;
  height: 24vh;
  position: absolute;
  top: -50px;
  right: 0;
  ${media.phone`
    width: 121px;
    height: 121px;
    border-width: 4px;
    right: 25px;
    top: -25px;
  `}
`;

const LeftBottomLine = styled(animated.div)`
  background: ${({ theme }) => theme.colors.pink};
  width: 4px;
  height: 33vh;
  position: absolute;
  bottom: -4px;
  left: 14%;
  transform: rotate(55deg);
  transform-origin: bottom;
  ${media.phone`
    left: -4px;
    bottom: 3%;
    width: 2px;
    height: 120px;
  `}
`;

const LeftBottomRadius = styled(animated.div)`
  width: 7.5vh;
  height: 7.5vh;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  position: absolute;
  bottom: 14%;
  left: 10%;
  ${media.phone`
    width: 46px;
    height: 46px;
    right: 53px;
    bottom: 69px;
    left: inherit;
  `}
`;

const LeftBottomRedEllipse = styled(animated.div)`
  width: 23vh;
  height: 23vh;
  border: 7.8px solid ${({ theme }) => theme.colors.pink};
  position: absolute;
  border-radius: 50%;
  left: 40%;
  bottom: 0;
  transform: translateY(45%);
  ${media.phone`
    width: 120px;
    height: 120px;
    border-width: 4px;
    left: 30%;
    transform: translateY(50%);
  `}
`;

const RightBottomBlueEllipse = styled(animated.div)`
  position: absolute;
  bottom: 3%;
  right: 2%;
  width: 14vh;
  height: 14vh;
  border: 7.8px solid ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  ${media.phone`
    width: 68px;
    height: 68px;
    border-width: 4px;
    bottom: 0;
    right: 0;
    transform: translate(55%, 50%);
  `}
`;

const RightBlueLine = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.blue};
  width: 4px;
  height: 33vh;
  transform: rotate(55deg);
  transform-origin: top right;
  position: absolute;
  right: -4px;
  bottom: 10%;
  ${media.phone`
    bottom: inherit;
    height: 80px;
    width: 2px;
    top: 12%;
  `}
`;

const RightCenterBlueRadius = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  top: 40%;
  right: 7%;
  width: 5.4vh;
  height: 5.4vh;
  border-radius: 50%;
  ${media.phone`
    display: none;
  `}
`;

const BottomInnerRadius = styled(animated.div)`
  width: 9vh;
  height: 9vh;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  position: absolute;
  right: -5%;
  bottom: 0;
  margin-right: -42px;
  margin-bottom: -42px;
  ${media.desktop`
    display: none;
  `}
  ${media.phone`
    display: none;
  `}
`;

const TopInnerRadius = styled(animated.div)`
  width: 5vh;
  height: 5vh;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  position: absolute;
  left: 30%;
  top: -15%;
  margin-right: -23px;
  margin-bottom: -23px;

  ${media.phone`
    width: 26px;
    height: 26px;
    left: 50%;
    top: -25%;
  `}
`;

const TextContent = styled.div`
  display: grid;
  position: relative;

  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.pink};
    font-size: 280px;
    display: block;
    line-height: 1;
    margin-bottom: 30px;
    ${media.tablet`
      font-size: 240px;
      text-align: center;
    `}
    ${media.phone`
      font-size: 105px;
      margin-bottom: 10px;
    `}
  }
  span {
    display: block;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 35px;
    ${media.phone`
      font-size: 18px;
    `}
  }

  & > button {
    justify-self: center;
    ${media.phone`
      width: 100%;
    `}
  }
`;
