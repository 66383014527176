import { handleActions, createActions } from 'redux-actions';
import history from 'lib/history';
import { createSelector } from 'reselect';
import { pipe, assoc } from 'ramda';
import 'moment/locale/ru';
// EFFECTS
import api from 'lib/api';
// FACTORIES
import createTracking from 'lib/factories/createTracking';
// DUCKS
import { effects as trackingsEffects } from './trackings.duck';

// INITIAL STATE
const initialState = {
  tracking: createTracking(),
  loading: false,
  error: null,
};

const actions = createActions(
  'FETCH_TRACKING_REQUEST',
  'FETCH_TRACKING_SUCCESS',
  'FETCH_TRACKING_FAILURE',

  'CREATE_TRACKING_SUCCESS',
  'CREATE_TRACKING_FAILURE',

  'UPDATE_TRACKING_SUCCESS',
  'UPDATE_TRACKING_FAILURE',

  'TRACKING_ADD_TRACKER_REQUEST',
  'TRACKING_ADD_TRACKER_SUCCESS',
  'TRACKING_ADD_TRACKER_FAILURE',
);

const reducer = handleActions(
  {
    [actions.trackingAddStartupRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.trackingAddStartupSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.trackingAddStartupFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),

    [actions.fetchTrackingRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.fetchTrackingSuccess]: (state, { payload: tracking }) => ({
      ...state,
      loading: false,
      tracking,
    }),
    [actions.fetchTrackingFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.createTrackingRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.createTrackingSuccess]: (state, { payload: tracking }) => ({
      ...state,
      loading: false,
      tracking,
    }),
    [actions.createTrackingFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.updateTrackingSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateTrackingFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.trackingAddTrackerRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.trackingAddTrackerSuccess]: state => ({
      ...state,
      loading: false,
      error: false,
    }),
    [actions.trackingAddTrackerFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  fetchTracking: id => async dispatch => {
    try {
      dispatch(actions.fetchTrackingRequest());
      const { data, meta } = await api.getTracking(id);
      if (meta.success) {
        dispatch(actions.fetchTrackingSuccess(data));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchTrackingFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  // $FlowFixMe
  createTracking: payload => async dispatch => {
    try {
      const { data, meta } = await api.createTracking(payload);
      if (meta.success) {
        dispatch(actions.createTrackingSuccess(data));
        if (payload.startup_ids.length > 0)
          history.push(`/trackings/${data.id}/add-tracker-to-tracking`);
      }
      return meta.success;
    } catch (error) {
      dispatch(actions.createTrackingFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  updateTracking: payload => async dispatch => {
    try {
      await api.updateTracking(payload);
      await dispatch(effects.fetchTracking(payload.id));
      dispatch(actions.updateTrackingSuccess());
      if (payload.startup_ids.length > 0)
        history.push(`/trackings/${payload.id}/edit-tracker-to-tracking`);
      return true;
    } catch (error) {
      dispatch(actions.updateTrackingFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  removeTracking: id => async dispatch => {
    try {
      const { meta } = await api.removeTracking(id);
      if (meta.success) {
        dispatch(actions.removeTrackingSuccess());
        await dispatch(trackingsEffects.fetchTrackings());
      }
      return meta.success;
    } catch (error) {
      dispatch(actions.createTrackingFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  trackingAddTracker: payload => async dispatch => {
    dispatch(actions.trackingAddTrackerRequest());
    try {
      await api.addTrackersToTrackingStartups(payload);
      dispatch(actions.trackingAddTrackerSuccess());
      history.push(`/trackings`);
      return true;
    } catch (error) {
      dispatch(actions.trackingAddTrackerFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
};

const getState = state => state.tracking;
const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

// $FlowFixMe
const selectors = {
  getTracking: cs(
    s =>
      s.tracking &&
      pipe(e =>
        assoc(
          'progress',
          `${Math.round(
            ((Date.now() - Date.parse(e.started_at)) /
              (Date.parse(e.ended_at) - Date.parse(e.started_at))) *
              100,
          )}`,
          e,
        ),
      )(s.tracking),
  ),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
};

export { initialState as state, reducer, actions, effects, selectors };
