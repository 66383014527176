// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';
import history from 'lib/history';
// DUCKS
import { actions as toastsActions } from 'redux/ducks/common/toasts.duck';
// INITIAL STATE
const initialState = {
  user: null,
  loading: false,
  error: null,
};

// ACTIONS
const actions = createActions(
  'FETCH_USER_REQUEST',
  'FETCH_USER_SUCCESS',
  'FETCH_USER_FAILURE',

  'CREATE_USER_REQUEST',
  'CREATE_USER_SUCCESS',
  'CREATE_USER_FAILURE',

  'UPDATE_USER_REQUEST',
  'UPDATE_USER_SUCCESS',
  'UPDATE_USER_FAILURE',
);

const effects = {
  // $FlowFixMe
  fetchUser: id => async dispatch => {
    dispatch(actions.fetchUserRequest());
    try {
      const { data, meta } = await api.getUser(id);
      if (meta.success) dispatch(actions.fetchUserSuccess(data));
      return meta.success;
    } catch (error) {
      dispatch(actions.fetchUserFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  // $FlowFixMe
  createUser: payload => async dispatch => {
    dispatch(actions.createUserRequest());
    try {
      await api.createUser(payload);
      dispatch(actions.createUserSuccess());
      history.push('/users');
      return true;
    } catch (error) {
      dispatch(actions.createUserFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  updateUser: payload => async dispatch => {
    dispatch(actions.updateUserRequest());
    try {
      await api.updateUser(payload);
      dispatch(actions.updateUserSuccess());
      history.push('/users');
      return true;
    } catch (error) {
      const {
        data: { errors = [] },
      } = error;
      errors.forEach(({ message }) => {
        Object.keys(message).forEach(key => {
          dispatch(
            toastsActions.addToast({
              text: `${key}: ${message[key]}`,
              error: true,
            }),
          );
        });
      });
      dispatch(actions.updateUserFailure(error));
      return new Promise(resolve => resolve(error));
    }
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchUserRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchUserSuccess]: (state, { payload: user }) => ({
      ...state,
      user,
      loading: false,
    }),
    [actions.fetchUserFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.createUserRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.createUserSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createUserFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.createUserRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.createUserSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateUserFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const getState = state => state.user;

const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

// $FlowFixMe
const selectors = {
  getUser: cs(s => s.user),
  getLoading: cs(s => s.loading),
  getErrors: cs(s => s.error),
};

export { initialState as state, actions, effects, reducer, selectors };
