
const saveToken = token => {
	localStorage.setItem('token', token);

	return localStorage.getItem('token');
};

const removeToken = () => localStorage.removeItem('token');

const getToken = () => localStorage.getItem('token');

export {
	saveToken,
	removeToken,
	getToken
};