// @flow
import { handleActions, createActions } from 'redux-actions';
import api from 'lib/api';
// DUCKS
import { effects as courseEffects } from './course.duck';
// INITIAL STATE
const initialState = {
  courseSection: null,
};
// ACTIONS
const actions = createActions(
  'CREATE_COURSE_SECTION_REQUEST',
  'CREATE_COURSE_SECTION_SUCCESS',
  'CREATE_COURSE_SECTION_FAILURE',

  'UPDATE_COURSE_SECTION_REQUEST',
  'UPDATE_COURSE_SECTION_SUCCESS',
  'UPDATE_COURSE_SECTION_FAILURE',

  'REMOVE_COURSE_SECTION_REQUEST',
  'REMOVE_COURSE_SECTION_SUCCESS',
  'REMOVE_COURSE_SECTION_FAILURE',
);
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.createCourseSectionRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.createCourseSectionSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createCourseSectionFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.updateCourseSectionRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.updateCourseSectionSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateCourseSectionFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.removeCourseSectionRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.removeCourseSectionSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.removeCourseSectionFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  createCourseSection: payload => async dispatch => {
    try {
      const { meta } = await api.createCourseSection(payload);
      if (meta.success) {
        dispatch(actions.createCourseSectionSuccess());
        await dispatch(courseEffects.fetchCourse(payload.course_id));
      }
      return true;
    } catch (error) {
      await dispatch(actions.createCourseSectionFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  updateCourseSection: payload => async dispatch => {
    try {
      const { meta } = await api.updateCourseSection(payload);
      if (meta.success) {
        dispatch(actions.updateCourseSectionSuccess());
        await dispatch(courseEffects.fetchCourse(payload.course_id));
      }
      return true;
    } catch (error) {
      await dispatch(actions.updateCourseSectionFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  removeCourseSection: payload => async dispatch => {
    try {
      const { meta } = await api.removeCourseSection(payload.id);
      if (meta.success) {
        await dispatch(actions.removeCourseSectionSuccess());
        await dispatch(courseEffects.fetchCourse(payload.course_id));
        return true;
      }
      return true;
    } catch (error) {
      await dispatch(actions.removeCourseSectionFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
};

export { initialState as state, actions, effects, reducer };
