export default {
  home: '/',
  dashboard: '/dashboard',
  users: '/users',
  addUser: '/users/add-user',
  editUser: '/users/:id/edit-user',
  signIn: '/signin',
  resetPass: '/signin/reset-pass',
  trackings: '/dashboard/trackings',
  tracking: '/dashboard/trackings/:id',
  trackingStartup: '/dashboard/tracking/startup/:trackingId/:startupId',
  weekDetail:
    '/dashboard/week/tracking/startup/:weekNumber/:trackingId/:startupId',
  profile: '/profile',
  courses: '/courses',
  // startups: '/dashboard/startups',
};
