export default options => ({
  id: null,
  name: '',
  description: '',
  logo: '',
  created_at: '',
  trackings: [],
  user: {
    id: null,
    name: '',
    last_name: '',
    city: '',
    avatar: '',
    email: '',
    phone: '',
  },
  ...options,
});
