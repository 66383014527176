// @flow
import * as React from 'react';
import styled from 'styled-components';

const Footer = () => <FooterLayout>Accel ©2019 Created by 2UP</FooterLayout>;

export default Footer;

const FooterLayout = styled.div`
  height: 60px;
  padding: 20px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;
