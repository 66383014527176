import * as yup from 'yup';

const validators = {};

const rusLetters = /^[а-яА-ЯЁё]+$/i;

validators.addEditAnketa = yup.object().shape({
  name: yup.string().required('Обязательное поле'),
  blocks: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Обязательное поле'),
      is_step_delimiter: yup.boolean(),
      questions: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Обязательное поле'),
          description: yup.string().required('Обязательное поле'),
          type_id: yup.number().min(1, 'Обязательное поле').required('Обязательное поле'),
          placeholder: yup.string().when('type_id', (type_id, schema) => {
            if (type_id === 1 || type_id === 2) {
              return schema.required('Обязательное поле');
            }
            return schema;
          }),
          available_answers: yup.array().nullable().when('type_id', (type_id, schema) => {
            if (type_id >= 3) {
              return schema.min(1, 'Обязательное поле');
            }
            return schema;
          })
        })
      )
    })
  )
});

validators.auth = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Обязательное поле';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Неверный адрес электронной почты';
  }

  if (values.password.length < 5) {
    errors.password = 'Пароль должен содержать шесть символов';
  }

  return errors;
};

validators.addEditUser = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле';
  }

  if (values.name.length < 3) {
    errors.password = 'Имя должно быть больше 3 символов';
  }

  if (!values.last_name) {
    errors.last_name = 'Обязательное поле';
  }

  if (values.phone && (values.phone[1] !== '7' || values.phone.length !== 17)) {
    errors.phone = 'Неверный формат номера';
  }

  if (!values.email) {
    errors.email = 'Обязательное поле';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Неверный адрес электронной почты';
  }

  if (!values.role_id) {
    errors.role_id = 'Обязательное поле';
  }

  return errors;
};

validators.addEditAdminProfile = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле';
  }

  if (values.name.length < 3) {
    errors.password = 'Имя должно быть больше 3 символов';
  }

  if (!values.last_name) {
    errors.last_name = 'Обязательное поле';
  }

  if (!values.email) {
    errors.email = 'Обязательное поле';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Неверный адрес электронной почты';
  }

  return errors;
};

validators.addEditAdminPassword = values => {
  const errors = {};

  if (!values.new_password) {
    errors.password = 'Обязательное поле';
  }

  if (!values.confirm_new_password) {
    errors.confirm_new_password = 'Обязательное поле';
  }

  if (values.new_password.length < 3) {
    errors.new_password = 'Пароль должен быть больше 3 символов';
  }

  if (values.confirm_new_password.length < 3) {
    errors.confirm_new_password = 'Пароль должен быть больше 3 символов';
  }

  if (
    values.new_password.length < 3 &&
    values.confirm_new_password !== values.password
  ) {
    errors.new_password = 'Пароли должны совпадать';
  }

  return errors;
};

validators.addEditStartup = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле';
  }

  if (!values.user_id) {
    errors.user_id = 'Обязательное поле';
  }

  return errors;
};

validators.addEditReview = values => {
  const errors = {};

  if (!values.owner) {
    errors.owner = 'Обязательное поле';
  }

  if (!values.owner_position) {
    errors.owner_position = 'Обязательное поле';
  }

  if (!values.title) {
    errors.title = 'Обязательное поле';
  }

  return errors;
};

validators.addReview = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле';
  }

  if (values.name.length < 3) {
    errors.password = 'Имя должно быть больше 3 символов';
  }

  if (!values.job) {
    errors.job = 'Обязательное поле';
  }

  return errors;
};

validators.addEditTracking = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле';
  }

  if (!values.started_at) {
    errors.started_at = 'Обязательное поле';
  }

  if (!values.ended_at) {
    errors.ended_at = 'Обязательное поле';
  }

  if (values.tracking_email) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.tracking_email)
    ) {
      errors.tracking_email = 'Неверный адрес электронной почты';
    }
  }

  if (Date.parse(values.started_at) > Date.parse(values.ended_at)) {
    errors.started_at = 'Дата начала не может быть больше даты конца';
    errors.ended_at = 'Дата начала не может быть больше даты конца';
  }

  return errors;
};

validators.addTracker = values => {
  const errors = {};

  if (!values.tracker_id) {
    errors.tracker_id = 'Обязательное поле';
  }

  return errors;
};

validators.addEditRole = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле';
  }

  if (values.name.length < 3) {
    errors.password = 'Имя должно быть больше 3 символов';
  }

  if (values.name.length > 3 && !rusLetters.test(values.name)) {
    errors.name = 'Ввод букв не русского алфавита запрещен';
  }

  return errors;
};

validators.addCourse = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Обязательное поле';
  }

  if (values.course_plan[values.course_plan.length - 1] === '') {
    errors.course_plan = 'Обязательное поле';
  }

  if (!values.hours) {
    errors.hours = 'Обязательное поле';
  }

  if (values.hours < 0) {
    errors.hours = 'Отрицательные значения запрещены';
  }

  if (values.hours > 10000) {
    errors.hours = 'Максимально допустимое значение - 10000';
  }

  if (!values.executed_count) {
    errors.executed_count = 'Обязательное поле';
  }

  if (values.executed_count > 10000) {
    errors.executed_count = 'Максимально допустимое значение - 10000';
  }

  if (values.executed_count < 0) {
    errors.executed_count = 'Отрицательные значения запрещены';
  }

  if (!values.rating) {
    errors.rating = 'Обязательное поле';
  }

  if (values.rating < 0) {
    errors.rating = 'Отрицательные значения запрещены';
  }
  if (values.rating > 5) {
    errors.rating = 'Максимально допустимое значение - 5';
  }

  if (values.price < 0) {
    errors.price = 'Отрицательные значения запрещены';
  }

  if (values.price > 100000) {
    errors.price = 'Максимально допустимое значение - 100000';
  }

  return errors;
};

export default validators;
