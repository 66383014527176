// @flow
import * as React from 'react';

import {
  MdBusiness,
  MdHome,
  MdPerson,
  MdSettings,
  MdSchool,
  MdStar,
  MdViewAgenda,
} from 'react-icons/md';

export default [
  {
    id: 1,
    exact: true,
    linkName: 'Главная',
    to: '/dashboard',
    icon: <MdHome />,
  },
  {
    id: 2,
    exact: false,
    linkName: 'Пользователи',
    to: '/users',
    icon: <MdPerson />,
    // subItems: [
    //   { id: 1, exact: true, linkName: 'Все пользователи', to: '/users' },
    //   { id: 2, exact: true, linkName: 'Добавить пользователя', to: '/add-user' },
    // ],
    disabled: false,
  },
  // {
  //   id: 3,
  //   exact: true,
  //   linkName: 'Трекеры',
  //   to: '#',
  //   icon: <MdPerson />,
  //   subItems: [
  //     { id: 1, exact: true, linkName: 'Все трекеры', to: '/trackers' },
  //     { id: 2, exact: true, linkName: 'Добавить трекера', to: '/add-tracker' },
  //   ],
  //   disabled: false,
  // },
  {
    id: 3,
    exact: false,
    linkName: 'Стартапы',
    to: '/startups',
    icon: <MdBusiness />,
    disabled: false,
  },
  {
    id: 4,
    exact: false,
    linkName: 'Наборы',
    to: '/trackings',
    icon: <MdViewAgenda />,
    disabled: false,
  },
  {
    id: 5,
    exact: false,
    linkName: 'Курсы',
    to: '/courses',
    icon: <MdSchool />,
    disabled: false,
  },
  {
    id: 6,
    exact: false,
    linkName: 'Отзывы',
    to: '/reviews',
    icon: <MdStar />,
    disabled: false,
  },
  {
    id: 7,
    exact: false,
    linkName: 'Настройки',
    to: '/settings',
    icon: <MdSettings />,
    disabled: false,
  },
];
