// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';
// INITIAL STATE
const initialState = {
  courses: [],
  loading: false,
  error: null,
};
// ACTIONS
const actions = createActions(
  'FETCH_COURSES_REQUEST',
  'FETCH_COURSES_FAILURE',
  'FETCH_COURSES_SUCCESS',
);

const effects = {
  // $FlowFixMe
  fetchCourses: () => async dispatch => {
    try {
      await dispatch(actions.fetchCoursesRequest());
      const { data, meta } = await api.getCourses();
      if (meta.success) {
        await dispatch(actions.fetchCoursesSuccess(data));
      }
    } catch (error) {
      dispatch(actions.fetchCoursesFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
    return true;
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchCoursesRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchCoursesSuccess]: (state, { payload: courses }) => ({
      ...state,
      courses,
      loading: false,
    }),
    [actions.fetchCoursesFailure]: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false,
    }),
  },
  initialState,
);

const getState = state => state.courses;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );
// $FlowFixMe
const selectors = {
  getCourses: cs(s => s.courses),
  getLoading: cs(s => s.loading),
  getErrors: cs(s => s.error),
};

export { initialState as state, actions, effects, reducer, selectors };
