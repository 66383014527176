// @flow
import { useCallback, useState } from 'react';

export const useTabs = (initialTab) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const handleActiveTab = useCallback(tab => setActiveTab(tab), []);

  return {
    activeTab,
    handleActiveTab,
  };
};


