// @flow
import { useCallback, useState } from 'react';

export const useSidebar = (initialState = false) => {
  const [ isSidebarOpen, setState ] = useState(initialState);
  const openSidebar = useCallback(() => setState(true), []);
  const closeSidebar = useCallback(() => setState(false), []);

  return {
    isSidebarOpen,
    openSidebar,
    closeSidebar
  };
};


