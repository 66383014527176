// @flow
import * as React from 'react';
import { createPortal } from 'react-dom';
// TYPES
type Props = {
  children: React.Node,
  modalRoot: HTMLElement,
};
// EXPORTED COMPONENT
const SidebarPortal = ({ children, modalRoot }: Props) =>
  createPortal(children, modalRoot);

SidebarPortal.defaultProps = {
  modalRoot: document.getElementById('modal-sidebar'),
};

export default SidebarPortal;
