// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { compose, pipe, assoc, map, find, propEq, prop, join } from 'ramda';
// API
import api from 'lib/api';
// HELPERS
import { customSort } from 'lib/helpers/sorting';

// INITIAL STATE
const initialState = {
  users: [],
  loading: false,
  errorMessage: null,
};

// ACTIONS
const actions = createActions(
  'FETCH_USERS_REQUEST',
  'FETCH_USERS_FAILURE',
  'FETCH_USERS_SUCCESS',
);

const effects = {
  // $FlowFixMe
  fetchUsers: () => async dispatch => {
    try {
      await dispatch(actions.fetchUsersRequest());
      const { data, meta } = await api.getUsers();
      if (meta.success) {
        await dispatch(actions.fetchUsersSuccess(data));
      }
    } catch (error) {
      dispatch(actions.fetchUsersFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
    return true;
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchUsersRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchUsersSuccess]: (state, { payload: users }) => ({
      ...state,
      users,
      loading: false,
    }),
    [actions.fetchUsersFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.updateUserSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateUserFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const getState = state => state.users;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

// $FlowFixMe
const selectors = {
  getUsers: cs(
    s =>
      s.users &&
      s.users.length >= 0 &&
      compose(
        pipe(e =>
          customSort(e, {
            key: 'id',
            keyType: 'number',
            direction: 'desc',
          }),
        ),

        map(
          pipe(e =>
            assoc(
              'userName',
              join(' ', [prop('name', e), prop('last_name', e)]),
              e,
            ),
          ),
        ),
      )(s.users),
  ),
  getUserStartups: cs(
    s =>
      s.users &&
      s.users.length >= 0 &&
      pipe(
        e => e.filter(user => user.roles.length > 0 && user.roles[0].id === 4),
        map(
          pipe(e =>
            assoc(
              'name',
              join(' ', [prop('name', e), prop('last_name', e)]),
              e,
            ),
          ),
        ),
      )(s.users),
  ),
  getUserTrackers: cs(s =>
    pipe(
      e => e.filter(({ roles }) => roles[0] && roles[0].id === 3),
      map(
        pipe(e =>
          assoc('name', join(' ', [prop('name', e), prop('last_name', e)]), e),
        ),
      ),
    )(s.users),
  ),
  getUserById: createSelector(
    [getState, getProps],
    (s, id) => s.users && find(propEq('id', id), s.users),
  ),

  getLoading: createSelector(
    [getState],
    s => s.loading,
  ),
};

export { initialState as state, actions, effects, reducer, selectors };
