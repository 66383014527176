// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';

// INITIAL STATE
const initialState = {
  applications: [],
  applicationsXlsx: '',
  applicationsLoading: false,
  applicationsLoaded: false,
  applicationsXlsxLoading: false,
  applicationsXlsxLoaded: false,
  errorMessage: null,
};

// ACTIONS
const actions = createActions(
  'FETCH_APPLICATIONS_REQUEST',
  'FETCH_APPLICATIONS_SUCCESS',
  'FETCH_APPLICATIONS_FAILURE',
  'FETCH_APPLICATIONS_XLSX_REQUEST',
  'FETCH_APPLICATIONS_XLSX_SUCCESS',
  'FETCH_APPLICATIONS_XLSX_FAILURE',
);

// REDUCERS
const reducer = handleActions(
  {
    [actions.fetchApplicationsRequest]: state => ({
      ...state,
      applications: [],
      applicationsLoading: true,
      applicationsLoaded: false,
    }),
    [actions.fetchApplicationsSuccess]: (state, { payload: applications }) => ({
      ...state,
      applications,
      applicationsLoading: false,
      applicationsLoaded: true,
    }),
    [actions.fetchApplicationsFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      applicationsLoading: false,
      applicationsLoaded: false,
    }),
    [actions.fetchApplicationsXlsxRequest]: state => ({
      ...state,
      applicationsXlsx: '',
      applicationsXlsxLoading: true,
      applicationsXlsxLoaded: false,
    }),
    [actions.fetchApplicationsXlsxSuccess]: (
      state,
      { payload: applicationsXlsx },
    ) => ({
      ...state,
      applicationsXlsx,
      applicationsXlsxLoading: false,
      applicationsXlsxLoaded: true,
    }),
    [actions.fetchApplicationsXlsxFailure]: (
      state,
      { payload: errorMessage },
    ) => ({
      ...state,
      errorMessage,
      applicationsXlsxLoading: false,
      applicationsXlsxLoaded: false,
    }),
  },
  initialState,
);

const effects = {
  fetchApplications: ({ trackingId, payload }) => async dispatch => {
    dispatch(actions.fetchApplicationsRequest());
    try {
      const { data, meta } = await api.getApplications(trackingId, payload);
      if (meta.success) {
        dispatch(actions.fetchApplicationsSuccess(data));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchApplicationsFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  fetchApplicationsXlsx: ({ trackingId, payload }) => async dispatch => {
    dispatch(actions.fetchApplicationsXlsxRequest());
    try {
      const { file } = await api.getApplicationsXlsx(trackingId, payload);
      if (file) {
        dispatch(actions.fetchApplicationsXlsxSuccess(file));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchApplicationsXlsxFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
};

const getState = state => state.applications;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getApplications: cs(s => s.applications),
  getApplicationsXlsx: cs(s => s.applicationsXlsx),
  getApplicationsLoading: cs(s => s.applicationsLoading),
  getApplicationsLoaded: cs(s => s.applicationsLoaded),
  getApplicationsXlsxLoading: cs(s => s.applicationsXlsxLoading),
  getApplicationsXlsxLoaded: cs(s => s.applicationsXlsxLoaded),
  getErrors: cs(s => s.error),
};

export { initialState as state, actions, effects, reducer, selectors };
