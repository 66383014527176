// @flow
import { useCallback, useState } from 'react';

export const useReadOnlyFields = (...fields) => {
  const initialState = {};
  fields.forEach(field => {
    initialState[field] = true;
  });
  const [isReadOnlyVals, setReadOnly] = useState(initialState);
  const handleReadOnly = useCallback((fieldName, fieldType = 'input') => {
    setReadOnly(prevIsReadOnlyVals => ({
      ...prevIsReadOnlyVals,
      [fieldName]: !prevIsReadOnlyVals[fieldName],
    }));
    document.querySelector(`${fieldType}[name=${fieldName}]`).focus();
  }, []);
  return {
    isReadOnlyVals,
    handleReadOnly,
  };
};
