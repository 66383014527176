// import ru from "lang/ru";
// import en from "lang/en";

const trans = (section, param, locale = "ru") => {
  if (locale === "ru") {
    // return ru[section][param];
  } else {
    //return en[section][param];
  }
};

export default trans;
