// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';

// INITIAL STATE
const initialState = {
  anketas: [],
  loading: false,
  loaded: false,
  errorMessage: null,
};

// ACTIONS
const actions = createActions(
  'FETCH_ANKETAS_REQUEST',
  'FETCH_ANKETAS_SUCCESS',
  'FETCH_ANKETAS_FAILURE',
);

// REDUCERS
const reducer = handleActions(
  {
    [actions.fetchAnketasRequest]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [actions.fetchAnketasSuccess]: (state, { payload: anketas }) => ({
      ...state,
      anketas,
      loading: false,
      loaded: true,
    }),
    [actions.fetchAnketasFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
      loaded: false,
    }),
  },
  initialState,
);

const effects = {
  fetchAnketas: () => async dispatch => {
    dispatch(actions.fetchAnketasRequest());
    try {
      const { data, meta } = await api.getAnketas();
      if (meta.success) {
        dispatch(actions.fetchAnketasSuccess(data));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchAnketasFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
};

const getState = state => state.anketas;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getAnketas: cs(s => s.anketas),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
  getLoaded: cs(s => s.loaded),
};

export { initialState as state, actions, effects, reducer, selectors };
