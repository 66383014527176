// export const searchByValue = (array, string, searchField) => {
//   if (string) {
//     return array.filter(o =>
//       Object.keys(o).some(k =>
//         String(o[k])
//           .toLowerCase()
//           .includes(string.toLowerCase()),
//       ),
//     );
//   }
//   return array;
// };

export const searchByValue = (array, string, searchField) => {
  if (string) {
    if (!searchField) {
      return array.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(string.toLowerCase()),
        ),
      );
    }

    if (searchField) {
      return array.filter(o =>
        o[searchField].toLowerCase().includes(string.toLowerCase()),
      );
    }
  }
  return array;
};


export const searchByRole = (array, roles, roleStr) => {
  if (roleStr) {
    return array.filter(o =>
     o.roles.some(role =>
        Object.keys(role).some(r =>
          String(role[r])
            .toLowerCase()
            .includes(roleStr.toLowerCase()),
        ),
      ),
    );
  }
  return array;
};