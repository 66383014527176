// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';
// FACTORIES
import createTest from 'lib/factories/createTest';
// DUCKS
import { actions as toastsActions } from './common/toasts.duck';
// INITIAL STATE
const initialState = {
  test: createTest(),
  loading: false,
  errorMessage: '',
};
// ACTIONS
const actions = createActions(
  'FETCH_TEST_REQUEST',
  'FETCH_TEST_SUCCESS',
  'FETCH_TEST_FAILURE',

  'CREATE_TEST_REQUEST',
  'CREATE_TEST_SUCCESS',
  'CREATE_TEST_FAILURE',

  'DELETE_TEST_REQUEST',
  'DELETE_TEST_SUCCESS',
  'DELETE_TEST_FAILURE',

  'CREATE_TEST_QUESTION_REQUEST',
  'CREATE_TEST_QUESTION_SUCCESS',
  'CREATE_TEST_QUESTION_FAILURE',

  'DELETE_TEST_QUESTION_REQUEST',
  'DELETE_TEST_QUESTION_SUCCESS',
  'DELETE_TEST_QUESTION_FAILURE',

  'EDIT_TEST_QUESTION_REQUEST',
  'EDIT_TEST_QUESTION_SUCCESS',
  'EDIT_TEST_QUESTION_FAILURE',

  'CREATE_TEST_ANSWER_REQUEST',
  'CREATE_TEST_ANSWER_SUCCESS',
  'CREATE_TEST_ANSWER_FAILURE',

  'DELETE_TEST_ANSWER_REQUEST',
  'DELETE_TEST_ANSWER_SUCCESS',
  'DELETE_TEST_ANSWER_FAILURE',

  'EDIT_TEST_ANSWER_REQUEST',
  'EDIT_TEST_ANSWER_SUCCESS',
  'EDIT_TEST_ANSWER_FAILURE',

  'CLEAR_TEST',
);

const effects = {
  // $FlowFixMe
  fetchTest: id => async dispatch => {
    try {
      dispatch(actions.fetchTestRequest());
      const { data, meta } = await api.getTest(id);
      if (meta.success) {
        dispatch(actions.fetchTestSuccess(data));
        return true;
      }
      throw new Error('Something goes wrong: fetchTest');
    } catch (error) {
      dispatch(actions.fetchTestFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  createTest: payload => async dispatch => {
    try {
      dispatch(actions.createTestRequest());
      const { data, meta } = await api.createTest(payload);
      const { id } = data;
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Тест создан' }));
        dispatch(actions.createTestSuccess());
        await dispatch(effects.fetchTest(id));
        return data;
      }
      throw new Error('Something goes wrong: createTest');
    } catch (error) {
      dispatch(toastsActions.addToast({ text: 'Тест не создан', error: true }));
      dispatch(actions.createTestFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  deleteTest: id => async dispatch => {
    try {
      dispatch(actions.deleteTestRequest());
      const { meta } = await api.deleteTest(id);
      if (meta.success) {
        dispatch(actions.deleteTestSuccess());
        return true;
      }
      throw new Error('Something goes wrong: deleteTest');
    } catch (error) {
      dispatch(actions.deleteTestFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  createTestQuestion: payload => async dispatch => {
    const { id, answers } = payload;
    try {
      dispatch(actions.createTestQuestionRequest());
      const { data, meta } = await api.createTestQuestion(payload);
      if (meta.success) {
        const promises = answers.map(el => {
          if (el.id) {
            return dispatch(
              effects.editTestAnswer({ test_id: id, id: data.id, ...el }),
            );
          }
          return dispatch(
            effects.createTestAnswer({ test_id: id, id: data.id, ...el }),
          );
        });
        await Promise.all(promises);
        dispatch(toastsActions.addToast({ text: 'Вопрос добавлен' }));
        dispatch(actions.createTestQuestionSuccess());
        await dispatch(effects.fetchTest(id));
        return data;
      }
      throw new Error('Something goes wrong: createTestQuestion');
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Вопрос не добавлен', error: true }),
      );
      dispatch(actions.createTestQuestionFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  deleteTestQuestion: ({ test_id, id }) => async dispatch => {
    try {
      dispatch(actions.deleteTestQuestionRequest());
      const { meta } = await api.deleteTestQuestion(id);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Вопрос удалён' }));
        dispatch(actions.deleteTestQuestionSuccess());
        await dispatch(effects.fetchTest(test_id));
        return true;
      }
      throw new Error('Something goes wrong: deleteTestQuestion');
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Вопрос не удалён', error: true }),
      );
      dispatch(actions.deleteTestQuestionFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  editTestQuestion: ({ test_id, answers, ...payload }) => async dispatch => {
    try {
      dispatch(actions.editTestQuestionRequest());
      const { data, meta } = await api.editTestQuestion(payload);
      if (meta.success) {
        const promises = answers.map(el => {
          if (el.id) {
            return dispatch(
              effects.editTestAnswer({ test_id, id: data.id, ...el }),
            );
          }
          return dispatch(
            effects.createTestAnswer({ test_id, id: data.id, ...el }),
          );
        });
        await Promise.all(promises);
        dispatch(toastsActions.addToast({ text: 'Вопрос изменён' }));
        dispatch(actions.editTestQuestionSuccess());
        await dispatch(effects.fetchTest(test_id));
      }
      return meta.success;
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Вопрос не изменён', error: true }),
      );
      dispatch(actions.editTestQuestionFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  createTestAnswer: ({ test_id, ...payload }) => async dispatch => {
    try {
      dispatch(actions.createTestAnswerRequest());
      const { data, meta } = await api.createTestAnswer(payload);
      if (meta.success) {
        dispatch(actions.createTestAnswerSuccess());
        await dispatch(effects.fetchTest(test_id));
        return data;
      }
      throw new Error('Something goes wrong: createTestAnswer');
    } catch (error) {
      dispatch(actions.createTestAnswerFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  deleteTestAnswer: ({ test_id, id }) => async dispatch => {
    try {
      dispatch(actions.deleteTestAnswerRequest());
      const { meta } = await api.deleteTestAnswer(id);
      if (meta.success) {
        dispatch(actions.deleteTestAnswerSuccess());
        await dispatch(effects.fetchTest(test_id));
        return true;
      }
      throw new Error('Something goes wrong: deleteTestAnswer');
    } catch (error) {
      dispatch(actions.deleteTestAnswerFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  editTestAnswer: ({ test_id, ...payload }) => async dispatch => {
    try {
      dispatch(actions.editTestAnswerRequest());
      const { meta } = await api.editTestAnswer(payload);
      if (meta.success) {
        dispatch(actions.editTestAnswerSuccess());
        await dispatch(effects.fetchTest(test_id));
        return true;
      }
      throw new Error('Something goes wrong: editTestAnswer');
    } catch (error) {
      dispatch(actions.editTestAnswerFailure(error.message));
      return error.message;
    }
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.fetchTestRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchTestSuccess]: (state, { payload: test }) => ({
      ...state,
      test,
      loading: false,
    }),
    [actions.fetchTestFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.createTestRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.createTestSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createTestFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.deleteTestRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.deleteTestSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.deleteTestFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),

    [actions.createTestQuestionRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.createTestQuestionSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createTestQuestionFailure]: (
      state,
      { payload: errorMessage },
    ) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.deleteTestQuestionRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.deleteTestQuestionSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.deleteTestQuestionFailure]: (
      state,
      { payload: errorMessage },
    ) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.editTestQuestionRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.editTestQuestionSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.editTestQuestionFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),

    [actions.createTestAnswerRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.createTestAnswerSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createTestAnswerFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.deleteTestAnswerRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.deleteTestAnswerSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.deleteTestAnswerFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.editTestAnswerRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.editTestAnswerSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.editTestAnswerFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.clearTest]: () => initialState,
  },
  initialState,
);

const getState = state => state.test;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

// $FlowFixMe
const selectors = {
  getTest: cs(s => s.test),
  getLoading: cs(s => s.loading),
};

export { initialState as state, actions, effects, reducer, selectors };
