// @flow
import * as React from 'react';
import styled, { css, type ReactComponentStyled } from 'styled-components';
import { MdAddCircle, MdDelete, MdClose, MdEdit } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';

// THEMES
import { primaryTheme } from 'lib/styles';

// TYPES
type Props = {
  bgColor?: string,
  circleSize?: string,
  withShadow?: boolean,
  color?: string,
  size?: string,
  iconName: string,
  type?: string,
};
// EXPORTED COMPONENT
const IconButton = (props: Props) => {
  const { color, withShadow, iconName, size, type } = props;
  const iconProps = { color, size };

  const Icons = {
    add: <MdAddCircle {...iconProps} />,
    delete: <MdDelete {...iconProps} />,
    remove: <MdClose {...iconProps} />,
    edit: <MdEdit {...iconProps} />,
    download: <FiDownload {...iconProps} />,
  };
  // eslint-disable-next-line
  const CurrentContent = type === 'button' ? Content : ContentLink;

  return (
    <CurrentContent withShadow={withShadow} {...props}>
      {Icons[iconName]}
    </CurrentContent>
  );
};

IconButton.defaultProps = {
  type: 'button',
  bgColor: primaryTheme.colors.pink,
  circleSize: '40px',
  size: '',
  withShadow: false,
  color: primaryTheme.colors.white,
};

export default IconButton;

const ContentStyle: ReactComponentStyled<{
  withShadow?: boolean,
  circleSize?: string,
  isDisabled?: boolean,
  bgColor?: string,
}> = css`
  display: flex;
  box-shadow: ${({ withShadow, theme }) =>
    withShadow && `0 3px 6px 0 ${theme.colors.transparentBlack30}`};
  justify-content: center;
  align-content: center;
  width: ${({ circleSize }) => circleSize};
  height: ${({ circleSize }) => circleSize};
  border-radius: 50%;
  background-color: ${({ bgColor, isDisabled, theme }) =>
    isDisabled ? theme.colors.lighterGrey : bgColor};
  cursor: pointer;
  border: none;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
  }
`;

const Content = styled.button`
  ${ContentStyle}
`;

const ContentLink = styled.a`
  ${ContentStyle}
`;
