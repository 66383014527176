// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';
// DUCKS
import { effects as applicationsEffects } from './applications.duck';

// INITIAL STATE
const initialState = {
  applicationPdf: '',
  applicationPdfLoading: false,
  applicationPdfLoaded: false,
  applicationAccepting: false,
  applicationAccepted: false,
  applicationRefusing: false,
  applicationRefused: false,
  errorMessage: null,
};

// ACTIONS
const actions = createActions(
  'ACCEPT_APPLICATION_REQUEST',
  'ACCEPT_APPLICATION_SUCCESS',
  'ACCEPT_APPLICATION_FAILURE',
  'REFUSE_APPLICATION_REQUEST',
  'REFUSE_APPLICATION_SUCCESS',
  'REFUSE_APPLICATION_FAILURE',
  'GET_APPLICATION_PDF_REQUEST',
  'GET_APPLICATION_PDF_SUCCESS',
  'GET_APPLICATION_PDF_FAILURE',
);

// REDUCERS
const reducer = handleActions(
  {
    [actions.acceptApplicationRequest]: state => ({
      ...state,
      applicationAccepting: true,
      applicationAccepted: false,
    }),
    [actions.acceptApplicationSuccess]: state => ({
      ...state,
      applicationAccepting: false,
      applicationAccepted: true,
    }),
    [actions.acceptApplicationFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      applicationAccepting: false,
      applicationAccepted: false,
    }),
    [actions.refuseApplicationRequest]: state => ({
      ...state,
      applicationRefusing: true,
      applicationRefused: false,
    }),
    [actions.refuseApplicationSuccess]: state => ({
      ...state,
      applicationRefusing: false,
      applicationRefused: true,
    }),
    [actions.refuseApplicationFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      applicationRefusing: false,
      applicationRefused: false,
    }),
    [actions.getApplicationPdfRequest]: state => ({
      ...state,
      applicationPdf: '',
      applicationPdfLoading: true,
      applicationPdfLoaded: false,
    }),
    [actions.getApplicationPdfSuccess]: (
      state,
      { payload: applicationPdf },
    ) => ({
      ...state,
      applicationPdf,
      applicationPdfLoading: false,
      applicationPdfLoaded: true,
    }),
    [actions.getApplicationPdfFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      applicationPdfLoading: false,
      applicationPdfLoaded: false,
    }),
  },
  initialState,
);

const effects = {
  acceptApplication: ({
    applicationId,
    trackingId,
    payload,
  }) => async dispatch => {
    dispatch(actions.acceptApplicationRequest());
    try {
      const { meta } = await api.acceptApplication(applicationId);
      if (meta.success) {
        dispatch(actions.acceptApplicationSuccess());
        dispatch(
          applicationsEffects.fetchApplications({ trackingId, payload }),
        );
        dispatch(
          applicationsEffects.fetchApplicationsXlsx({ trackingId, payload }),
        );
      }
      return true;
    } catch (error) {
      dispatch(actions.acceptApplicationFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  refuseApplication: ({
    applicationId,
    trackingId,
    payload,
  }) => async dispatch => {
    dispatch(actions.refuseApplicationRequest());
    try {
      const { meta } = await api.refuseApplication(applicationId);
      if (meta.success) {
        dispatch(actions.refuseApplicationSuccess());
        dispatch(
          applicationsEffects.fetchApplications({ trackingId, payload }),
        );
        dispatch(
          applicationsEffects.fetchApplicationsXlsx({ trackingId, payload }),
        );
      }
      return true;
    } catch (error) {
      dispatch(actions.refuseApplicationFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  getApplicationPdf: applicationId => async dispatch => {
    dispatch(actions.getApplicationPdfRequest());
    try {
      const { file } = await api.fetchApplicationPdf(applicationId);
      if (file) {
        dispatch(actions.getApplicationPdfSuccess(file));
      }
      return true;
    } catch (error) {
      dispatch(actions.getApplicationPdfFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
};

const getState = state => state.application;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getApplicationPdf: cs(s => s.applicationPdf),
  getApplicationPdfLoading: cs(s => s.applicationPdfLoading),
  getApplicationPdfLoaded: cs(s => s.applicationPdfLoaded),
  getApplicationAccepting: cs(s => s.applicationAccepting),
  getApplicationAccepted: cs(s => s.applicationAccepted),
  getApplicationRefusing: cs(s => s.applicationRefusing),
  getApplicationRefused: cs(s => s.applicationRefused),
  getError: cs(s => s.errorMessage),
};

export { initialState as state, actions, effects, reducer, selectors };
