export default (course, lesson_id) => {
  const result =
    course &&
    course.sections.find(section =>
      section.lessons.find(lesson => {
        return Number(lesson.id) === Number(lesson_id);
      }),
    );
  return result && result.id;
};
