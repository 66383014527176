// @flow
import * as React from 'react';
import { Route } from 'react-router-dom';
// COMPONENTS
import { LoadingScreen, ErrorScreen } from '../Screens';
import { AuthLayout } from '../Layouts';
// TYPES
type Props = {
  component: React.ComponentType<any>,
  rest: Object,
};

const AuthTemplate = ({ component: Component, ...rest }: Props) => (
  <Route
    {...rest}
    render={props => (
      <ErrorScreen>
        <AuthLayout {...rest}>
          <Component
            {...props}
            fallback={<LoadingScreen {...props} {...rest} />}
          />
        </AuthLayout>
      </ErrorScreen>
    )}
  />
);

export default AuthTemplate;
