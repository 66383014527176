// @flow
import * as React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';
// THEMES
import { primaryTheme } from 'lib/styles';
// TYPES
type Props = {
  name: string,
  history: Object,
  withBorder?: boolean,
  type?: string,
  navigateTo?: string,
  color?: string,
  size?: string,
  iconName: string,
  noAction: Boolean,
};

const LinkButton = (props: Props) => {
  const {
    name,
    history,
    withBorder,
    navigateTo,
    color,
    size,
    iconName,
    noAction = false,
  } = props;
  const iconProps = { color, size };

  const Icons = {
    download: <FiDownload {...iconProps} />,
  };

  const handleClick = () => {
    if (noAction) return null;
    return history.push(navigateTo);
  };

  return (
    <Content withBorder={withBorder} onClick={handleClick} {...props}>
      {name}
      {Icons[iconName]}
    </Content>
  );
};

LinkButton.defaultProps = {
  withBorder: false,
  type: 'button',
  navigateTo: '#',
  size: '',
  color: primaryTheme.colors.white,
};

export default withRouter(LinkButton);

const Content = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 360px;
  border: ${({ theme, withBorder }) =>
    withBorder ? `1px dashed ${theme.colors.pink}` : 'none'};
  color: ${({ theme, withBorder }) =>
    withBorder ? theme.colors.pink : theme.colors.white};
  background-color: ${({ theme, withBorder }) =>
    withBorder ? theme.colors.white : theme.colors.pink};
  cursor: pointer;
  font-size: 16px;
  font-family: 'Montserrat';
  outline: none;
  transition: all 0.2s ease;

  & > svg {
    margin-left: 19px;
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
    filter: opacity(50%);
    pointer-events: none;
  }

  /* &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${props => props.theme.colors.pink};
  }
  &:active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${props => props.theme.colors.pink};
  } */
`;
