import { request } from 'lib/helpers';

// const config = {
//   onUploadProgress: progressEvent => {
//     const percentCompleted = Math.round(
//       (progressEvent.loaded * 100) / progressEvent.total,
//     );
//   },
// };

const getConfig = cb => {
  return {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (cb) cb(percentCompleted);
    },
  };
};

export default {
  // getJsonWebToken: async ({ email, password }) => {
  //   try {
  //     return await request('post', 'auth/login', { email, password });
  //   } catch (error) {
  //       if (error.status === 422) {
  //         const message = view(
  //           lensPath(['data', 'errors', '0', 'message']),
  //           error,
  //         );
  //         throw { form: message };
  //       }

  //   }
  // },

  // ROLES
  getRoles: () => request('get', 'admin/roles'),
  getRole: id => request('get', `admin/roles/${id}`),
  updateRole: ({ id, ...params }) =>
    request('put', `admin/roles/${id}`, params),
  createRole: ({ id, ...params }) => request('post', 'admin/roles', params),
  removeRole: id => request('delete', `admin/roles/${id}`),

  // GET CREATE UPDATE DELETE COURSE(S)
  getCourses: () => request('get', 'admin/courses'),
  getCourse: id => request('get', `admin/courses/${id}`),
  createCourse: ({ id, ...params }) => request('post', 'admin/courses', params),
  createCourseSection: ({ id, ...params }) =>
    request('post', 'admin/sections', params),
  updateCourseSection: ({ id, ...params }) =>
    request('put', `admin/sections/${id}`, params),
  removeCourseSection: id => request('delete', `admin/sections/${id}`),
  fetchCourseSectionLesson: id => request('get', `admin/lessons/${id}`),
  createCourseSectionLesson: ({ id, ...params }) =>
    request('post', 'admin/lessons', params),
  updateCourseSectionLesson: ({ id, ...params }) =>
    request('put', `admin/lessons/${id}`, params),
  removeCourseSectionLesson: id => request('delete', `admin/lessons/${id}`),
  updateCourse: ({ id, ...params }) =>
    request('put', `admin/courses/${id}`, params),
  removeCourse: id => request('delete', `admin/courses/${id}`),
  uploadFilesLesson: (id, params, cb) =>
    request('post', `admin/lessons/${id}/upload`, params, getConfig(cb)),

  // GET CREATE UPDATE DELETE USER(S)
  getProfile: () => request('get', 'user/me'),
  getUsers: () => request('get', 'admin/users'),
  getUser: id => request('get', `admin/users/${id}`),
  updateUser: ({ id, ...params }) =>
    request('put', `admin/users/${id}`, params),
  createUser: ({ id, ...params }) => request('post', 'admin/users', params),

  // GET CREATE UPDATE DELETE STARTUP(S)
  getStartups: () => request('get', 'admin/startups'),
  getStartup: id => request('get', `admin/startups/${id}`),
  createStartup: ({ id, ...params }) =>
    request('post', 'admin/startups', params),
  updateStartup: ({ id, ...params }) =>
    request('put', `admin/startups/${id}`, params),

  // GET CREATE UPDATE DELETE STARTUP(S)
  getReviews: () => request('get', 'admin/reviews'),
  getReview: id => request('get', `admin/reviews/${id}`),
  createReview: ({ id, ...params }) => request('post', 'admin/reviews', params),
  updateReview: ({ id, ...params }) =>
    request('put', `admin/reviews/${id}`, params),
  deleteReview: id => request('delete', `admin/reviews/${id}`),

  // TRACKINGS
  getTrackings: () => request('get', 'admin/trackings'),
  getUserTrackings: () => request('get', 'user/trackings'),
  trackingAddStartup: params =>
    request('post', 'admin/startup/add/tracking', params),
  getTracking: id => request('get', `admin/trackings/${id}`),
  createTracking: ({ ...params }) => request('post', 'admin/trackings', params),
  updateTracking: ({ id, ...params }) =>
    request('put', `admin/trackings/${id}`, params),
  removeTracking: id => request('delete', `admin/trackings/${id}`),
  addTrackersToTrackingStartups: ({ id, ...params }) =>
    request('put', `admin/trackings/${id}/startup-attach`, params),

  getJsonWebToken: ({ email, password }) =>
    request('post', 'admin/auth/login', {
      email,
      password,
    }),

  logout: () => request('post', 'auth/logout'),
  resetPass: email => request('post', 'auth/password/restore', { email }),
  updatePassword: ({ id, params }) =>
    request('post', `user/edit/password/${id}`, params),

  getUserStartups: () => request('get', 'user/startups'),
  createAccelPlan: params => request('post', 'accel-plan/create', params),
  createWeekPlan: params => request('post', 'week-plan/create'),
  updateWeekPlan: params => request('post', 'week-plan/edit', params),
  createWeekReport: params => request('post', 'week-report/create', params),

  getTrackingAllStartups: trackingId =>
    request('get', `/info/all/${trackingId}`),

  getWeekDetail: ({ weekNumber, trackingId, startupId }) =>
    request('get', 'info/week-data', {
      week: weekNumber,
      tracking_id: trackingId,
      startup_id: startupId,
    }),
  createWeekEstimate: params => request('post', 'estimate/create', params),
  updateWeekEstimate: params => request('post', 'estimate/edit', params),
  updateWeekReport: params => request('post', 'week-report/edit', params),

  // ==== Program Course
  addEducationProgram: params => request('post', 'education/program', params),
  updateEducationProgram: params => request('put', 'education/program', params),
  getEducationProgram: () => request('get', 'education/program'),
  getEducationDetail: id => request('get', `education/program/${id}`),
  removeEducationProgram: id => request('delete', `/education/program/${id}`),

  // ==== Program Theme
  createProgramTheme: ({ name, program_id }) =>
    request('post', `education/program/${program_id}/theme`, {
      name,
      program_id,
    }),
  updateProgramTheme: ({ program_id, theme_id, ...params }) =>
    request('put', `education/program/${program_id}/theme/${theme_id}`, params),
  getProgramThemes: program_id =>
    request('get', `education/program/${program_id}/theme`),
  getProgramTheme: ({ program_id, theme_id }) =>
    request('get', `education/program/${program_id}/theme/${theme_id}`),
  removeEducationTheme: ({ program_id, theme_id }) =>
    request('delete', `education/program/${program_id}/theme/${theme_id}`),

  // ==== Education Sections
  getEducationSection: theme_id =>
    request('get', `education/theme/${theme_id}/section`),
  createEducationSection: ({ theme_id, ...params }) =>
    request('post', `education/theme/${theme_id}/section`, params),
  editEducationSection: ({ theme_id, section_id, ...params }) =>
    request('put', `education/theme/${theme_id}/section/${section_id}`, params),
  deleteEducationSection: ({ section_id, theme_id }) =>
    request('delete', `education/theme/${theme_id}/section/${section_id}`),

  // ==== Education Lesson
  createEducationLesson: ({ section_id, ...params }) =>
    request('post', `education/section/${section_id}/lesson`, params),
  editEducationLesson: params =>
    request('post', 'education/lesson/edit', params),
  deleteEducationLesson: lesson_id =>
    request('delete', `education/lesson/delete/${lesson_id}`),

  // ==== Attachment
  downloadAttachment: uuid => request('get', `attachment/download/${uuid}`),
  deleteAttachment: uuid => request('delete', `attachment/delete/${uuid}`),
  editAttachment: ({ uuid, ...params }) =>
    request('patch', `attachment/${uuid}`, params),

  // ==== Questionary
  createQuestionary: params => request('post', `admin/questionaries`, params),
  deleteQuestionary: id => request('delete', `admin/questionaries/${id}`),
  editQuestionary: ({ id, ...params }) =>
    request('put', `admin/questionaries/${id}`, params),

  // ==== Lesson Test
  getTest: id => request('get', `admin/tests/${id}`),
  createTest: params => request('post', 'admin/tests/', params),
  deleteTest: id => request('delete', `admin/tests/${id}`),

  createTestQuestion: ({ id, ...params }) =>
    request('post', `admin/tests/${id}/questions/`, params),
  editTestQuestion: ({ id, ...params }) =>
    request('put', `admin/questions/${id}`, params),
  deleteTestQuestion: id => request('delete', `admin/questions/${id}`),

  createTestAnswer: ({ id, ...params }) =>
    request('post', `admin/questions/${id}/answers`, params),
  editTestAnswer: ({ id, ...params }) =>
    request('put', `admin/answers/${id}`, params),
  deleteTestAnswer: id => request('delete', `admin/answers/${id}`),

  // ==== Anketas
  getAnketas: () => request('get', 'admin/requests'),
  getAnketa: anketaId => request('get', `admin/requests/${anketaId}`),
  createAnketa: data => request('post', 'admin/requests', data),
  updateAnketa: (anketaId, data) => request('put', `admin/requests/${anketaId}`, data),
  deleteAnketa: anketaId => request('delete', `admin/requests/${anketaId}`),

  // ==== Handbook
  getHandbook: () => request('get', 'admin/handbook/all'),

  // ==== Applications
  getApplications: (trackingId, statusId) =>
    request('get', `admin/trackings/${trackingId}/requests/records`, {
      params: { status_id: statusId }
    }),
  getApplicationsXlsx: (trackingId, statusId) =>
  request('get', `admin/trackings/${trackingId}/requests/records/xlsx`, {
    params: { status_id: statusId }
  }),

  // ==== Application
  acceptApplication: applicationId => request('patch', `admin/requests/results/${applicationId}/accept`),
  refuseApplication: applicationId => request('patch', `admin/requests/results/${applicationId}/refuse`),
  fetchApplicationPdf: applicationId => request('get', `admin/requests/results/${applicationId}/pdf`),

  // ==== Statistic
  getStatistic: (courseId, mode) => request('get', `admin/statistics/courses/${courseId}`, { params: { mode } }),
  getStatisticXlsx: (courseId, mode) => request('get', `admin/statistics/courses/${courseId}/xlsx`, { params: { mode } }),
  getQuestionary: (courseId, userId) => request('get', `admin/statistics/courses/${courseId}/questionaries/${userId}`),

  // ==== Startups
  getStartupPdf: (trackingId, startupId) => request('get', `admin/trackings/${trackingId}/startups/${startupId}/tracktion`),
  getStartupZip: (trackingId, startupId) => request('get', `admin/trackings/${trackingId}/startups/${startupId}/files`),
};
