import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'lib/history';
// API
import api from 'lib/api';

const initialState = {
  roles: [],
  role: null,
  error: false,
  loading: false,
};

const actions = createActions(
  'FETCH_ROLES_REQUEST',
  'FETCH_ROLES_SUCCESS',
  'FETCH_ROLES_FAILURE',

  'FETCH_ROLE_REQUEST',
  'FETCH_ROLE_SUCCESS',
  'FETCH_ROLE_FAILURE',

  'CREATE_ROLE_SUCCESS',
  'CREATE_ROLE_FAILURE',

  'UPDATE_ROLE_SUCCESS',
  'UPDATE_ROLE_FAILURE',

  'REMOVE_ROLE_SUCCESS',
  'REMOVE_ROLE_FAILURE',
);

const reducer = handleActions(
  {
    [actions.fetchRolesRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.fetchRolesSuccess]: (state, { payload: roles }) => ({
      ...state,
      roles,
      loading: false,
    }),
    [actions.fetchRolesFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.fetchRoleRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.fetchRoleSuccess]: (state, { payload: role }) => ({
      ...state,
      role,
      loading: false,
    }),
    [actions.fetchRoleFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.createRoleSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createRoleFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.updateRoleSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.updateRoleFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.removeRoleSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.removeRoleFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  fetchRoles: () => async dispatch => {
    try {
      await dispatch(actions.fetchRolesRequest());
      const { data, meta } = await api.getRoles();
      if (meta.success) {
        await dispatch(actions.fetchRolesSuccess(data));
      }
      return meta.success;
    } catch (error) {
      dispatch(actions.fetchRolesFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  // $FlowFixMe
  fetchRole: id => async dispatch => {
    try {
      await dispatch(actions.fetchRoleRequest());
      const { data, meta } = await api.getRole(id);
      if (meta.success) {
        await dispatch(actions.fetchRoleSuccess(data));
      }
      return meta.success;
    } catch (error) {
      dispatch(actions.fetchRoleFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  createRole: payload => async dispatch => {
    try {
      await api.createRole(payload);
      await Promise.all([
        dispatch(actions.createRoleSuccess()),
        dispatch(effects.fetchRoles()),
      ]);
      history.push('/settings');
      return true;
    } catch (error) {
      await dispatch(actions.createRoleFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  updateRole: payload => async dispatch => {
    try {
      await api.updateRole(payload);
      await Promise.all([
        dispatch(actions.updateRoleSuccess()),
        dispatch(effects.fetchRoles()),
      ]);
      history.push('/settings');
      return true;
    } catch (error) {
      await dispatch(actions.updateRoleFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  removeRole: id => async dispatch => {
    try {
      const { meta } = await api.removeRole(id);
      if (meta.success) {
        await dispatch(actions.removeRoleSuccess());
        await dispatch(effects.fetchRoles());
      }
      return meta.success;
    } catch (error) {
      await dispatch(actions.removeRoleFailure(error.message));
      return new Promise(resolve => resolve(error));
    }
  },
};

const getState = state => state.roles;
const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getRoles: cs(s => s.roles),
  getFormattedRoles: cs(
    s =>
      s.roles && [
        { id: 0, name: 'Все пользователи', slug: 'All users' },
        ...s.roles,
      ],
  ),
  getRole: cs(s => s.role),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
};

export { initialState as state, reducer, actions, selectors, effects };
