// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
// import { MdKeyboardArrowDown } from 'react-icons/md';
// // HOOKS
// import { useSetGlobalEventHandler } from 'lib/hooks';
// // COMPONENTS
// import Divider from 'components/UI/Divider';
// TYPES
type Props = {
  linkName: string,
  icon: React.Node,
  subItems: Array<Object>,
  toggleSubItems: Function,
};

// const { useState } = React;

// EXPORTED COMPONENT
const MenuItem = ({ icon, linkName: name, ...rest }: Props) => {
  // const [subItemsOpened, toggleSubItems] = useState(false);
  // const handleToggleSubItems = () =>
  //   toggleSubItems(prevSubItemsOpened => !prevSubItemsOpened);
  // const { subItems } = rest;
  // const handleHideNavDropdown = e => {
  //   if (e.target.nodeName === 'svg') return;
  //   toggleSubItems(false);
  // };

  // useSetGlobalEventHandler(document, 'click', handleHideNavDropdown);
  return (
    // <Content onClick={() => handleToggleSubItems()}>
    <Content>
      <MainLink {...rest}>
        <IconAndName {...rest}>
          <p>{name}</p>
          {/* {subItems && (
            <DropdownButton {...rest} subItemsOpened={subItemsOpened} />
          )} */}
        </IconAndName>
      </MainLink>
      {/* <SubLinks {...rest} subItemsOpened={subItemsOpened}>
        {subItems &&
          subItems.map(({ id, linkName, ...itemRest }) => (
            <>
              <SubItem key={id} {...itemRest}>
                {linkName}
              </SubItem>
              <Divider />
            </>
          ))}
      </SubLinks> */}
    </Content>
  );
};

export default MenuItem;

const activeClassName = 'active';

const Content = styled.div`
  position: relative;
  display: grid;
  transition: all 0.2s;

  p {
    margin: 0;
  }
`;

const MainLink = styled(NavLink).attrs({ activeClassName })`
  padding: 0 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  justify-self: start;
  text-decoration: none;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: ${({ theme }) => theme.colors.lightGrey};
      svg {
        fill: ${({ theme }) => theme.colors.lightGrey};
      }
    `}
  :active,
  :focus {
    text-decoration: none;
  }

  &.${activeClassName} {
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
  }
`;

// const DropdownButton = styled(MdKeyboardArrowDown).attrs({ size: '30px' })`
//   justify-self: end;
//   transform: ${({ subItemsOpened }) => subItemsOpened && 'rotate(-90deg)'};
//   transition: transform 0.2s;
// `;

// const SubLinks = styled.div`
//   position: absolute;
//   top: 30px;
//   left: 15px;
//   width: 186px;
//   /* border-right: 1px solid ${({ theme }) => theme.colors.lightGrey}; */
//   /* padding: ${({ subItemsOpened, subItems }) =>
//     subItems && subItemsOpened ? '6px' : '0'}; */
//   grid-gap: 4px;
//   background-color: ${({ theme }) => theme.colors.white};
//   transition: all 0.2s;
//   visibility: ${({ subItemsOpened }) =>
//     subItemsOpened ? 'visible' : 'hidden'};
//   opacity: ${({ subItemsOpened }) => (subItemsOpened ? '1' : '0')};
//   height: ${({ subItemsOpened }) => (subItemsOpened ? 'auto' : '0%')};
//   a {
//     display: ${({ subItemsOpened }) => (subItemsOpened ? 'block' : 'none')};
//     font-size: 14px;
//   }
// `;

// const SubItem = styled(NavLink).attrs({ activeClassName })`
//   padding: 5px 6px;
//   font-size: 16px;
//   text-decoration: none;
//   color: ${({ theme }) => theme.colors.black};
//   &.${activeClassName} {
//     color: ${({ theme }) => theme.colors.pink};
//   }
//   :hover {
//     color: ${({ theme }) => theme.colors.black};
//     background-color: ${({ theme }) => theme.colors.lightGrey};
//   }
// `;

const IconAndName = styled.div`
  color: inherit;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-content: center;
`;
