export default options => ({
  id: null,
  name: '',
  description: '',
  test: null,
  videos: [],
  attachments: [],
  questionaries: [],
  ...options,
});
