// @flow
import * as React from 'react';
import styled from 'styled-components/macro';
import Spinner from 'components/UI/Spinner';
// EXPORTED COMPONENT
const LoadingScreen = () => (
  <Content>
    <Spinner />
  </Content>
);

export default LoadingScreen;

const Content = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.colors.white};
  > section {
    height: 100px;
    width: 100px;
    position: absolute;
    z-index: 1001;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
