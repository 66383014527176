// @flow
import * as React from 'react';
import { Route } from 'react-router-dom';
// COMPONENTS
import { LoadingScreen, ErrorScreen } from '../Screens';
import { MainLayout } from '../Layouts';
// TYPES
type Props = {
  component: React.ComponentType<any>,
  rest: Object,
};

const MainTemplate = ({ component: Component, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorScreen>
          <MainLayout {...rest}>
            <Component
              {...props}
              fallback={<LoadingScreen {...props} {...rest} />}
            />
          </MainLayout>
        </ErrorScreen>
      )}
    />
  );
};

export default MainTemplate;
