let id = 0;

const defaultOptions = {
  text: 'Information toast',
  error: false,
};

export default function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
    id: ++id,
  };
}
