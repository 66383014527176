// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';
// DUCKS
import { effects as lessonEffects } from './lesson.duck';
import { actions as toastsActions } from './common/toasts.duck';
// INITIAL STATE
const initialState = {
  loading: false,
  errorMessage: '',
};
// ACTIONS
const actions = createActions(
  'CREATE_QUESTIONARY_REQUEST',
  'CREATE_QUESTIONARY_SUCCESS',
  'CREATE_QUESTIONARY_FAILURE',

  'DELETE_QUESTIONARY_REQUEST',
  'DELETE_QUESTIONARY_SUCCESS',
  'DELETE_QUESTIONARY_FAILURE',

  'EDIT_QUESTIONARY_REQUEST',
  'EDIT_QUESTIONARY_SUCCESS',
  'EDIT_QUESTIONARY_FAILURE',
);

const effects = {
  // $FlowFixMe
  createQuestionary: payload => async dispatch => {
    const { lesson_id } = payload;
    try {
      await dispatch(actions.createQuestionaryRequest());
      const { meta } = await api.createQuestionary(payload);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Вопрос добавлен' }));
        await dispatch(actions.createQuestionarySuccess());
        await dispatch(lessonEffects.fetchCourseSectionLesson(lesson_id));
        return true;
      }
      throw new Error('Something goes wrong: createQuestionary');
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Вопрос не добавлен', error: true }),
      );
      dispatch(actions.createQuestionaryFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  deleteQuestionary: ({ lesson_id, id }) => async dispatch => {
    try {
      await dispatch(actions.deleteQuestionaryRequest());
      const { meta } = await api.deleteQuestionary(id);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Вопрос удалён' }));
        await dispatch(actions.deleteQuestionarySuccess());
        await dispatch(lessonEffects.fetchCourseSectionLesson(lesson_id));
        return true;
      }
      throw new Error('Something goes wrong: deleteQuestionary');
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Вопрос не удалён', error: true }),
      );
      dispatch(actions.deleteQuestionaryFailure(error.message));
      return error.message;
    }
  },
  // $FlowFixMe
  editQuestionary: payload => async dispatch => {
    const { lesson_id } = payload;
    try {
      await dispatch(actions.editQuestionaryRequest());
      const { meta } = await api.editQuestionary(payload);
      if (meta.success) {
        dispatch(toastsActions.addToast({ text: 'Вопрос изменён' }));
        await dispatch(actions.editQuestionarySuccess());
        await dispatch(lessonEffects.fetchCourseSectionLesson(lesson_id));
        return true;
      }
      throw new Error('Something goes wrong: editQuestionary');
    } catch (error) {
      dispatch(
        toastsActions.addToast({ text: 'Вопрос не изменён', error: true }),
      );
      dispatch(actions.editQuestionaryFailure(error.message));
      return error.message;
    }
  },
};
// $FlowFixMe
const reducer = handleActions(
  {
    [actions.createQuestionaryRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.createQuestionarySuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.createQuestionaryFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.deleteQuestionaryRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.deleteQuestionarySuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.deleteQuestionaryFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
    [actions.editQuestionaryRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.editQuestionarySuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.editQuestionaryFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    }),
  },
  initialState,
);

const getState = state => state.questionary;

// $FlowFixMe
const selectors = {
  getLoading: createSelector(
    [getState],
    s => s.loading,
  ),
};

export { initialState as state, actions, effects, reducer, selectors };
