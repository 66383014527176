// @flow
import * as React from 'react';
import styled, { css, type ReactComponentStyled } from 'styled-components';
import Spinner from 'react-spinkit';
import { primaryTheme } from 'lib/styles';

// TYPES
type Props = {
  children?: React.Node,
  disabled?: boolean,
  name: string,
  history: Object,
  size?: string,
  withDashedBorder?: boolean,
  buttonColor?: string,
  white: boolean,
  type?: string,
  isLoading?: boolean,
  onClick: Function,
};

const Button = (props: Props) => {
  const {
    name,
    children,
    size,
    withDashedBorder,
    isLoading,
    buttonColor,
    onClick,
  } = props;
  return (
    <Content
      withDashedBorder={withDashedBorder}
      size={size}
      onClick={() => onClick}
      buttonColor={buttonColor}
      {...props}
    >
      {isLoading ? (
        <Spinner
          fadeIn="none"
          className="spinner"
          name="circle"
          color={
            buttonColor === 'pink'
              ? primaryTheme.colors.white
              : primaryTheme.colors.lightGrey
          }
        />
      ) : (
        <>
          {children}
          <span>{name}</span>
        </>
      )}
    </Content>
  );
};

Button.defaultProps = {
  withDashedBorder: false,
  type: 'button',
  buttonColor: 'green',
  size: '',
  isLoading: false,
  disabled: false,
  children: null,
};

export default React.memo(Button);

const whiteStyles: ReactComponentStyled<{
  withDashedBorder?: boolean,
  height?: string,
  disabled?: boolean,
}> = css`
  border: ${({ theme, withDashedBorder }) =>
    withDashedBorder
      ? `1px dashed ${theme.colors.pink}`
      : `1px solid ${theme.colors.pink}`};
  color: ${({ theme }) => theme.colors.pink};
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ height }) => height};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
`;

const pinkStyles: ReactComponentStyled<{
  withDashedBorder?: boolean,
  height?: string,
}> = css`
  border: 1px solid
    ${({ theme, withDashedBorder }) => withDashedBorder && theme.colors.pink};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.pink};
  height: ${({ height }) => height};
`;

const blueStyles: ReactComponentStyled<{
  withDashedBorder?: boolean,
  height?: string,
}> = css`
  border: 1px solid
    ${({ theme, withDashedBorder }) => withDashedBorder && theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  height: ${({ height }) => height};
`;

const Content: ReactComponentStyled<{
  buttonColor?: string,
  height?: string,
  width?: string,
  size?: string,
}> = styled.button`
  ${({ buttonColor }) =>
    (buttonColor === 'pink' && pinkStyles) ||
    (buttonColor === 'blue' && blueStyles) ||
    (buttonColor === 'white' && whiteStyles)}
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height || '60px'};
  width: ${({ width }) => width || 'auto'};
  min-width: ${({ size }) => size || '155px'};

  font-weight: 600;
  font-size: 18px;
  font-family: 'Montserrat';
  padding: ${({ size }) => (size === 'sm' ? '0px 10px' : '0px 30px')};

  box-shadow: ${({ theme }) => `0 3px 6px 0 ${theme.colors.transparentPink}`};
  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: all 0.2s ease;

  span {
    padding-left: 8px;
  }

  .spinner {
    margin: auto;
  }

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
    filter: opacity(50%);
    pointer-events: none;
  }
`;
