import formatString from 'format-string-by-pattern';
// import { parse } from 'url';
// import numeral from 'numeral';

// export const fieldId = value => value;
// export const parseLabel = value => (value ? value.label : '');

// export const parseAddress = value => {
//   if (value) {
//     const { label, location } = value;
//     return { label, location };
//   }
//   return null;
// };
// export const formatAddress = value =>
//   value && value !== null ? value.label : null;

// export const numberWithCommas = x => Number(x).toLocaleString('en');

// export const clean = e => {
//   return e && numeral(e)._value;
// };

// export const cleanMileage = e => {
//   if (e && numeral(e)._value) {
//     const val = numeral(e)._value;
//     let formattedVal = val;
//     if (val.toString().length > 9) {
//       formattedVal = val.toString().slice(0, 9);
//     }
//     return formattedVal;
//   }
//   return '';
// };

// export const parseDate = e => e.format('YYYY-MM-DD');
// export const removeSpecialChar = e =>
//   e &&
//   e
//     .toString()
//     .replace(/[^A-Za-z0-9 ]/g, '')
//     .replace(' ', '')
//     .toUpperCase();

// export const parseMileage = val => {
//   if (val) {
//     let formattedVal = val;
//     numeral.defaultFormat('0,0');
//     if (val.toString().length > 9) {
//       formattedVal = val.toString().slice(0, 9);
//     }

//     return numeral(formattedVal).format();
//   }
//   return val;
// };

// export const parsePrice = val => {
//   if (val) {
//     numeral.defaultFormat('0,0.00');
//     return numeral(val).format();
//   }
//   return val;
// };

// export const formatOnlyNumbers = (anyString, mask) => {
//   const onlyNumbers = anyString.toString().replace(/[^\d]/g, '');
//   return onlyNumbers.length ? formatString(mask, onlyNumbers) : '';
// };

// export const parseYear = e => e && formatOnlyNumbers(e, 'YYYY');

export const formatPhone = anyString => {
  if (anyString) {
    const onlyNumbers = anyString.toString().replace(/[^\d]/g, '');
    return formatString('+9(999)-999-99-99', onlyNumbers);
  }
  return anyString;
};
