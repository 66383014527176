// @flow
import * as React from 'react';
import RemovalConfirmation from './render/RemovalConfirmation';
import ErrorConfirmation from './render/ErrorConfirmation';

const RenderModal = ({ type, args, ...props }) => {
  switch (type) {
    case 'removalConfirmation':
      return <RemovalConfirmation {...args} {...props} />;
    case 'errorConfirmation':
      return <ErrorConfirmation {...args} {...props} />;
    default:
      return null;
  }
};

export default RenderModal;
