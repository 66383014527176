// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// STYLES
import { media } from 'lib/styles';
// ASSETS
import background from 'assets/images/homeBg.jpg';
import mainLogo from 'assets/images/itpark-logo_small.png';

// TYPES
type Props = Object;

// EXPORTED
const AuthLayout = ({ children, ...rest }: Props) => (
  <Content {...rest}>
    <Link to="/">
      <LogoImg alt="logo" src={mainLogo} />
    </Link>
    <Grid>{children}</Grid>
  </Content>
);

export default AuthLayout;

const Content = styled.main`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Grid = styled.section`
  flex: 1;
  display: grid;
  justify-items: center;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  ${media.phone`
    background: none;
  `}
`;

const LogoImg = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  display: none;
  max-width: 60px;
  height: 60px;
  transform: translate(-50%, 50%);
  ${media.phone`
    display: block;
  `}
`;
