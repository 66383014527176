import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import history from 'lib/history';
import { lensPath, view } from 'ramda';
// HELPERS
import { removeToken, saveToken, getToken } from 'lib/helpers/token';
// COMPONENTS
import routes from 'lib/routes';
// API
import api from 'lib/api';
// DUCKS
import { effects as adminEffects } from './admin.duck';

const initialState = {
  token: null,
  error: false,
  loading: false,
};

const actions = createActions(
  'AUTH_LOGIN_SUCCESS',
  'AUTH_LOGIN_FAILURE',

  'AUTH_LOGOUT_SUCCESS',
  'AUTH_LOGOUT_FAILURE',

  'AUTH_REFRESH_TOKEN_SUCCESS',
  'AUTH_REFRESH_TOKEN_FAILURE',

  'RESET_PASS_SUCCESS',
  'RESET_PASS_FAILURE',
);

const reducer = handleActions(
  {
    [actions.authLoginSuccess]: (state, { payload: token }) => ({
      ...state,
      token,
      loading: false,
    }),
    [actions.authLoginFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.resetPassSuccess]: state => ({
      ...state,
      loading: false,
    }),
    [actions.resetPassFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
    [actions.authRefreshTokenSuccess]: (state, { payload: { token } }) => ({
      ...state,
      token,
      loading: false,
    }),
    [actions.authRefreshTokenFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),

    [actions.authLogoutRequest]: state => ({
      ...state,
      loading: true,
    }),
    [actions.authLogoutSuccess]: () => ({
      ...initialState,
    }),
    [actions.authLogoutFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  authLogin: (credentials, setErrors, setSubmitting) => async dispatch => {
    try {
      const userData = await api.getJsonWebToken(credentials);
      const {
        data: { access_token },
      } = userData;

      await Promise.all([
        dispatch(actions.authLoginSuccess(access_token)),
        saveToken(access_token),
        setSubmitting(false),
      ]);
      return history.push(routes.courses);
    } catch (error) {
      setSubmitting(false);
      if (error.status === 422) {
        const message = view(lensPath(['data', 'errors', 'message']), error);
        return new Promise(resolve => resolve(setErrors({ form: message })));
      }
      if (error.status === 403) {
        const message = view(
          lensPath(['data', 'errors', '0', 'message']),
          error,
        );
        return new Promise(resolve => resolve(setErrors({ form: message })));
      }
      return new Promise(resolve => resolve(error));
    }
  },
  // $FlowFixMe
  authRefreshToken: () => dispatch => {
    try {
      const token = getToken();
      if (token) dispatch(actions.authRefreshTokenSuccess({ token }));
      return true;
    } catch (error) {
      dispatch(actions.authRefreshTokenFailure());
      return false;
    }
  },
  // $FlowFixMe
  authLogout: () => dispatch => {
    removeToken();
    dispatch(actions.authLogoutSuccess());
    history.push(routes.signIn);
  },
  // $FlowFixMe
  getInitialData: () => async dispatch => {
    try {
      await dispatch(adminEffects.fetchProfile());
    } catch (error) {
      throw error;
    }
    return true;
  },
  // $FlowFixMe
  resetPassword: data => async dispatch => {
    try {
      await api.resetPass(data);
      await dispatch(actions.resetPassSuccess());
      // await dispatch(push('/login'));
    } catch (error) {
      actions.resetPassFailure(error);
      return new Promise(resolve => resolve(error));
    }
    return true;
  },
};

const getState = state => state.auth;
const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getToken: cs(s => s.token),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
};

export { initialState as state, reducer, actions, selectors, effects };
