// @flow
export const primaryTheme = {
  colors: {
    // WHITE
    white: '#fff',
    whiteSmoke: '#f2f2f2',
    // GREY
    lighterGrey: '#c4c4c4',
    lightGrey: '#e5e5e5',
    platinumGrey: '#DADADA',
    darkGrey: '#ababab',

    // PINK
    pink: '#e40043',
    transparentPink: 'rgba(250, 134, 134, 0.1)',

    // BLUE
    blue: '#3E80FF',
    transparentBlue: 'rgba(0, 126, 255, 0.1)',

    // GREEN
    darkGreen: '#009D90',
    lightSeaGreen: '#3BBBB3',
    lightMagenta: '#A4E375',

    // BLACK
    black: '#191919',
    transparentBlack: 'rgba(0, 0, 0, 0.075)',
    transparentBlack70: 'rgba(0, 0, 0, 0.7)',
    transparentBlack15: 'rgba(0, 0, 0, 0.15)',

  },
  buttonSizes: {
    md: '136px',
    lg: '172px',
    xlg: '200px',
  },
  boxSizes: {
    sm: '239px',
    md: '328px',
  },
  paddings: {
    md: '24px',
  },
};

export const secodaryTheme = {
  // main: 'mediumseagreen',
};
