// @flow
import * as React from 'react';
// HOOKS
import { useSearch } from 'lib/hooks';
// TYPES
import { Roles } from 'lib/types';
// HELPERS
import { searchByRole } from 'lib/helpers/searchFilters';

// TYPES
type Props = {
  items: Array<Object>,
  roles: Roles,
  rest: Object,
};

// EXPORTED HOC
const withSearchRoles = WrappedComponent => ({
  items,
  roles,
  ...rest
}: Props) => {
  const { searchKeyword, searchByKeyword } = useSearch(null);
  const filteredItems = searchByRole(items, roles, searchKeyword);
  return (
    <WrappedComponent
      {...rest}
      items={filteredItems}
      roles={roles}
      searchByKeyword={searchByKeyword}
    />
  );
};

export default withSearchRoles;
