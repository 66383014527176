// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'ramda';
// DUCKS
import { actions, selectors } from 'redux/ducks/common/toasts.duck';
// COMPONENTS
import Toast from './Toast';

type Props = { toasts: Array, removeToast: Function };

const Toasts = (props: Props) => {
  const { removeToast, toasts } = props;
  return (
    <ToastsWrapper>
      {toasts.map(toast => (
        <Toast key={toast.id} removeToast={removeToast} {...toast} />
      ))}
    </ToastsWrapper>
  );
};

export default compose(
  connect(
    state => ({
      toasts: selectors.getToasts(state),
    }),
    {
      ...actions,
    },
  ),
)(Toasts);

const ToastsWrapper = styled.ul`
  position: fixed;
  top: 5%;
  right: 5%;
  min-width: 30%;
  width: max-content;
  max-width: 90%;
`;
