import { sort } from 'ramda';

export const dynamicSort = property => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line
    property = property.substr(1);
  }

  return (a, b) => {
    if (typeof b[property] === 'string' || a[property] === 'string') {
      // eslint-disable-next-line
    if (sortOrder == -1) {
        if (b[property] === null) {
          return b[property];
        }
        if (b[property] === undefined) {
          b[property] = null;
          return b[property];
        }
        return b[property].localeCompare(a[property]);
      }
      if (a[property] === null) {
        return a[property];
      }
      if (b[property] === undefined) {
        b[property] = null;
        return b[property];
      }
      if (a[property] === undefined) {
        a[property] = null;
        return a[property];
      }
      return a[property].localeCompare(b[property]);
    }

    if (typeof b[property] === 'number' || a[property] === 'number') {
      // eslint-disable-next-line
      if (sortOrder == -1) {
        if (b[property] === null) {
          return b[property];
        }
        return b[property] - a[property];
      }
      if (a[property] === null) {
        return a[property];
      }
      return a[property] - b[property];
    }
  };
};

// Compare
const compareNumberAsc = key => (a, b) => {
  const aProp = a[key];
  const bProp = b[key];

  return aProp - bProp;
};

const compareNumberDesc = key => (a, b) => {
  const aProp = a[key];
  const bProp = b[key];
  return bProp - aProp;
};

const compareStringAsc = key => (a, b) => {
  const aProp = a[key];
  const bProp = b[key];

  return aProp > bProp;
};

const compareStringDesc = key => (a, b) => {
  const aProp = a[key];
  const bProp = b[key];

  return aProp < bProp;
};

const compareDateAsc = key => (a, b) => {
  const aProp = a[key];
  const bProp = b[key];

  return new Date(aProp) - new Date(bProp);
};

const compareDateDesc = key => (a, b) => {
  const aProp = a[key];
  const bProp = b[key];

  return new Date(bProp) - new Date(aProp);
};

// options => key, keyType, direction

export const customSort = (arr, options) => {
  const { key, keyType, direction } = options;
  if (keyType === 'number') {
    if (typeof direction === 'string' && direction.toLowerCase() === 'desc') {
      return sort(compareNumberDesc(key), arr);
    }
    return sort(compareNumberAsc(key), arr);
  }

  if (keyType === 'string') {
    if (typeof direction === 'string' && direction.toLowerCase() === 'desc') {
      return sort(compareStringDesc(key), arr);
    }
    return sort(compareStringAsc(key), arr);
  }

  if (keyType === 'date') {
    if (typeof direction === 'string' && direction.toLowerCase() === 'desc') {
      return sort(compareDateDesc(key), arr);
    }
    return sort(compareDateAsc(key), arr);
  }
};
