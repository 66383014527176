import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
// import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import transforms, { blacklist, whitelist } from 'redux/transformFilters';
import storage from 'redux-persist/lib/storage/session';
import rootReducer from './rootReducer';
import { resetStore } from './middleware';

// PERSIST STORE
const persistConfig = {
  key: 'root',
  storage,
  blacklist,
  whitelist,
  transforms,
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

const middleware = composeWithDevTools(
  applyMiddleware(
    thunk,
    // createLogger()
  ),
  resetStore(),
);

export const store = createStore(persistedReducer, middleware);
export const persistor = persistStore(store);

export default () => {
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(persistedReducer);
      });
    }
  }
  return { store, persistor };
};
