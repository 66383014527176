// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';

// INITIAL STATE
const initialState = {
  handbook: [],
  loading: false,
  loaded: false,
  errorMessage: null,
};

// ACTIONS
const actions = createActions(
  'FETCH_HANDBOOK_REQUEST',
  'FETCH_HANDBOOK_SUCCESS',
  'FETCH_HANDBOOK_FAILURE',
);

// REDUCERS
const reducer = handleActions(
  {
    [actions.fetchHandbookRequest]: state => ({
      ...state,
      loading: true,
      loaded: false,
    }),
    [actions.fetchHandbookSuccess]: (state, { payload: handbook }) => ({
      ...state,
      handbook,
      loading: false,
      loaded: true,
    }),
    [actions.fetchHandbookFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
      loaded: false,
    }),
  },
  initialState,
);

const effects = {
  fetchHandbook: () => async dispatch => {
    dispatch(actions.fetchHandbookRequest());
    try {
      const response = await api.getHandbook();
      dispatch(actions.fetchHandbookSuccess(response));
      return true;
    } catch (error) {
      dispatch(actions.fetchHandbookFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
};

const getState = state => state.handbook;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getHandbook: cs(s => s.handbook),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
  getLoaded: cs(s => s.loaded),
};

export { initialState as state, actions, effects, reducer, selectors };
