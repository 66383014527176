// @flow
import { useCallback, useState } from 'react';

export const usePagination = (itemsPerPage) => {
  const [ page, setPage ] = useState(1);

  const handlePageNavigation = useCallback(pageNum => setPage(pageNum), []);
  const toFirstPage = useCallback(() => setPage(1), []);

  return {
    itemsPerPage,
    page,
    handlePageNavigation,
    toFirstPage,
  };
};


