// @flow
import * as React from 'react';
// HOOKS
import { useSearch } from 'lib/hooks';
// HELPERS
import { searchByValue } from 'lib/helpers/searchFilters';

// TYPES
type Props = {
  items: Array<Object>,
  rest: Object,
};

// EXPORTED HOC
const withSearch = searchField => WrappedComponent => ({
  items,
  ...rest
}: Props) => {
  const { searchKeyword, searchByKeyword } = useSearch(null);
  let filteredItems;
  if (searchField) {
    filteredItems = searchByValue(items, searchKeyword, searchField);
  }

  if (!searchField) {
    filteredItems = searchByValue(items, searchKeyword);
  }

  return (
    <WrappedComponent
      {...rest}
      items={filteredItems}
      searchByKeyword={searchByKeyword}
    />
  );
};

export default withSearch;
