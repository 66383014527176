// @flow
import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';

// INITIAL STATE
const initialState = {
  statistic: [],
  statisticLoading: false,
  statisticLoaded: false,
  statisticXlsx: '',
  statisticXlsxLoading: false,
  statisticXlsxLoaded: false,
  statisticQuestionary: [],
  statisticQuestionaryLoading: false,
  statisticQuestionaryLoaded: false,
  courseCertificate: '',
  courseCertificateLoading: false,
  courseCertificateLoaded: false,
  errorMessage: null,
};

// ACTIONS
const actions = createActions(
  'FETCH_STATISTIC_REQUEST',
  'FETCH_STATISTIC_SUCCESS',
  'FETCH_STATISTIC_FAILURE',
  'FETCH_STATISTIC_XLSX_REQUEST',
  'FETCH_STATISTIC_XLSX_SUCCESS',
  'FETCH_STATISTIC_XLSX_FAILURE',
  'FETCH_STATISTIC_QUESTIONARY_REQUEST',
  'FETCH_STATISTIC_QUESTIONARY_SUCCESS',
  'FETCH_STATISTIC_QUESTIONARY_FAILURE',
);

// REDUCERS
const reducer = handleActions(
  {
    [actions.fetchStatisticRequest]: state => ({
      ...state,
      statistic: [],
      statisticLoading: true,
      statisticLoaded: false,
    }),
    [actions.fetchStatisticSuccess]: (state, { payload: statistic }) => ({
      ...state,
      statistic,
      statisticLoading: false,
      statisticLoaded: true,
    }),
    [actions.fetchStatisticFailure]: (state, { payload: errorMessage }) => ({
      ...state,
      errorMessage,
      statisticLoading: false,
      statisticLoaded: false,
    }),
    [actions.fetchStatisticXlsxRequest]: state => ({
      ...state,
      statisticXlsx: '',
      statisticXlsxLoading: true,
      statisticXlsxLoaded: false,
    }),
    [actions.fetchStatisticXlsxSuccess]: (
      state,
      { payload: statisticXlsx },
    ) => ({
      ...state,
      statisticXlsx,
      statisticXlsxLoading: false,
      statisticXlsxLoaded: true,
    }),
    [actions.fetchStatisticXlsxFailure]: (
      state,
      { payload: errorMessage },
    ) => ({
      ...state,
      errorMessage,
      statisticXlsxLoading: false,
      statisticXlsxLoaded: false,
    }),
    [actions.fetchStatisticQuestionaryRequest]: state => ({
      ...state,
      statisticQuestionaryLoading: true,
      statisticQuestionaryLoaded: false,
    }),
    [actions.fetchStatisticQuestionarySuccess]: (
      state,
      { payload: statisticQuestionary },
    ) => ({
      ...state,
      statisticQuestionary,
      statisticQuestionaryLoading: false,
      statisticQuestionaryLoaded: true,
    }),
    [actions.fetchStatisticQuestionaryFailure]: (
      state,
      { payload: errorMessage },
    ) => ({
      ...state,
      errorMessage,
      statisticQuestionaryLoading: false,
      statisticQuestionaryLoaded: false,
    }),
  },
  initialState,
);

const effects = {
  fetchStatistic: ({ courseId, mode }) => async dispatch => {
    dispatch(actions.fetchStatisticRequest());
    try {
      const { meta, data } = await api.getStatistic(courseId, mode);
      if (meta.success) {
        dispatch(actions.fetchStatisticSuccess(data));
        dispatch(effects.fetchStatisticXlsx({ courseId, mode }));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchStatisticFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  fetchStatisticXlsx: ({ courseId, mode }) => async dispatch => {
    dispatch(actions.fetchStatisticXlsxRequest());
    try {
      const { file } = await api.getStatisticXlsx(courseId, mode);
      if (file) {
        dispatch(actions.fetchStatisticXlsxSuccess(file));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchStatisticXlsxFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
  fetchStatisticQuestionary: ({ courseId, userId }) => async dispatch => {
    dispatch(actions.fetchStatisticQuestionaryRequest());
    try {
      const { meta, data } = await api.getQuestionary(courseId, userId);
      if (meta.success) {
        dispatch(actions.fetchStatisticQuestionarySuccess(data));
      }
      return true;
    } catch (error) {
      dispatch(actions.fetchStatisticQuestionaryFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
  },
};

const getState = state => state.statistic;
const getProps = (state, props) => props;
const cs = cb =>
  createSelector(
    [getState, getProps],
    cb,
  );

const selectors = {
  getStatistic: cs(s => s.statistic),
  getStatisticLoading: cs(s => s.statisticLoading),
  getStatisticLoaded: cs(s => s.statisticLoaded),
  getStatisticXlsx: cs(s => s.statisticXlsx),
  getStatisticXlsxLoading: cs(s => s.statisticXlsxLoading),
  getStatisticXlsxLoaded: cs(s => s.statisticXlsxLoaded),
  getStatisticQuestionary: cs(s => s.statisticQuestionary),
  getStatisticQuestionaryLoading: cs(s => s.statisticQuestionaryLoading),
  getStatisticQuestionaryLoaded: cs(s => s.statisticQuestionaryLoaded),
  getError: cs(s => s.errorMessage),
};

export { initialState as state, actions, effects, reducer, selectors };
