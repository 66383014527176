// @ flow
import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { MdClose } from 'react-icons/md';
// STYLES
import { media } from 'lib/styles';

// TYPES
type Props = {
  closeModal: Function,
  children: React.Node,
  maxWidth?: number,
  style: Object,
};

const ModalContainer = ({ closeModal, maxWidth, children, style }: Props) => (
  <CloseBackground onClick={() => closeModal()}>
    <Container style={{ ...style }}>
      <ModalDialog maxWidth={maxWidth}>
        <Header>
          <CloseButton onClick={() => closeModal()}>
            <Close size="30px" />
          </CloseButton>
        </Header>
        {children}
      </ModalDialog>
    </Container>
  </CloseBackground>
);

export default ModalContainer;

ModalContainer.defaultProps = {
  maxWidth: 500,
  title: '',
};

const ModalDialog = styled.div`
  padding: 20px 20px 40px;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  ${media.phone`
      padding-left: 15px;
      padding-right: 15px;
  `};
`;
const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Close = styled(MdClose)`
  cursor: pointer;
  :hover,
  :active {
    opacity: 0.8;
  }
`;

const CloseBackground = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-overflow-scrolling: touch;
  z-index: -1;
`;

const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

const Container = styled(animated.div)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  opacity: 0;
`;
