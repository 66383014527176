import { handleActions, createActions } from 'redux-actions';
import { createSelector } from 'reselect';
// API
import api from 'lib/api';

const initialState = {
  reviews: [],
  error: false,
  loading: false,
};

const actions = createActions(
  'FETCH_REVIEWS_REQUEST',
  'FETCH_REVIEWS_SUCCESS',
  'FETCH_REVIEWS_FAILURE',
);

const reducer = handleActions(
  {
    [actions.fetchReviewsRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
    [actions.fetchReviewsSuccess]: (state, { payload: reviews }) => ({
      ...state,
      reviews,
      loading: false,
    }),
    [actions.fetchReviewsFailure]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState,
);

const effects = {
  // $FlowFixMe
  fetchReviews: () => async dispatch => {
    try {
      await dispatch(actions.fetchReviewsRequest());
      const { data, meta } = await api.getReviews();
      if (meta.success) {
        await dispatch(actions.fetchReviewsSuccess(data));
      }
    } catch (error) {
      dispatch(actions.fetchReviewsFailure(error.message));
      return new Promise(resolve => resolve(error.message));
    }
    return true;
  },
};

const getState = state => state.reviews;
const cs = cb =>
  createSelector(
    [getState],
    cb,
  );

const selectors = {
  getReviews: cs(s => s.reviews),
  getErrors: cs(s => s.error),
  getLoading: cs(s => s.loading),
};

export { initialState as state, reducer, actions, selectors, effects };
