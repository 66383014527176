// @flow
import * as React from 'react';
// HOOKS
import { useSidebar, usePrevious, useSetGlobalEventHandler } from 'lib/hooks';
// HELPERS
import { throttle } from 'lib/helpers';

const { useEffect } = React;

// TYPES
type Props = Object;

// EXPORTED HOC
const withSidebar = WrappedComponent => (props: Props) => {
  const { location } = props;
  const prevLocation = usePrevious(location.pathname);
  const sidebarProps = useSidebar(null);
  let touchObj = null;
  let startX;
  let sidebarRef;
  let dist;
  let leftPosition;

  const handleOpenSidebar = () => {
    sidebarRef = document.getElementById('sidebar');
    leftPosition = sidebarRef.getBoundingClientRect().left;
    sidebarProps.openSidebar();
    if (leftPosition === -290) {
      sidebarRef.style.transform = 'translateX(0)';
    }
  };

  const handleCloseSidebar = () => {
    sidebarRef = document.getElementById('sidebar');
    leftPosition = sidebarRef.getBoundingClientRect().left;
    sidebarProps.closeSidebar();
    if (leftPosition === 0) {
      sidebarRef.style.transform = 'translateX(-290px)';
    }
  };
  // eslint-disable-next-line
  const handleTouchStart = throttle(function(event) {
    // eslint-disable-next-line
    touchObj = event.changedTouches[0];
    startX = parseInt(touchObj.clientX, 10);
  }, 100);

  // eslint-disable-next-line
  const handleTouchMove = throttle(function(event) {
    sidebarRef = document.getElementById('sidebar');
    leftPosition = sidebarRef.getBoundingClientRect().left;
    // eslint-disable-next-line
    touchObj = event.changedTouches[0];
    dist = parseInt(touchObj.clientX, 10) - startX;
    let num = -290;
    num = dist > 290 && leftPosition < 0 ? 0 : -290;

    if (event.target !== sidebarRef) {
      sidebarRef.style.transform = `translateX(${num}px)`;
      if (num === 0) {
        sidebarProps.openSidebar();
      }
      if (num < 0) {
        sidebarProps.closeSidebar();
      }
    }
  }, 100);

  const onEscKeyPressed = event => {
    if (event.keyCode === 27) {
      if (sidebarProps.isSidebarOpen) handleCloseSidebar();
      return null;
    }
    return null;
  };

  useSetGlobalEventHandler(
    window,
    'keydown',
    onEscKeyPressed,
    sidebarProps.isSidebarOpen,
  );
  useSetGlobalEventHandler(window, 'touchstart', handleTouchStart);
  useSetGlobalEventHandler(window, 'touchmove', handleTouchMove);
  useEffect(() => {
    if (prevLocation && prevLocation !== location.pathname) {
      const sidebar = document.getElementById('sidebar');
      const { left } = sidebar.getBoundingClientRect();
      if (left === 0) {
        sidebar.style.transform = 'translateX(-290px)';
      }
      sidebarProps.closeSidebar();
    }
    // eslint-disable-next-line
  }, [location.pathname]);
  return (
    <WrappedComponent
      {...props}
      sidebarProps={sidebarProps}
      handleOpenSidebar={handleOpenSidebar}
      handleCloseSidebar={handleCloseSidebar}
    />
  );
};

export default withSidebar;
